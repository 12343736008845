export const gamesInStorm = {
	fortnite: "Fortnite",
	dota: "Dota2",
};
export enum PERCENTILES {
	TOP01 = 0.01,
	TOP09 = 0.09,
	TOP15 = 0.15,
}
export const MAX_REWARD_PER_PERCENTILES = {
	[PERCENTILES.TOP01]: 81.290323,
	[PERCENTILES.TOP09]: 9.032258,
	[PERCENTILES.TOP15]: 5.419355,
};
export const BASE_REWARD_PER_PERCENTILES = {
	[PERCENTILES.TOP01]: 60,
	[PERCENTILES.TOP09]: 7,
	[PERCENTILES.TOP15]: 4,
};
const XP_TO_LVL = {
	0: 1,
	2: 2,
	4: 3,
	6: 4,
	8: 5,
	10: 6,
	12: 7,
	14: 8,
	16: 9,
	19: 10,
	23: 11,
	28: 12,
	34: 13,
	41: 14,
	49: 15,
	58: 16,
	68: 17,
	79: 18,
	91: 19,
	105: 20,
	121: 21,
	139: 22,
	159: 23,
	181: 24,
	205: 25,
	231: 26,
	259: 27,
	289: 28,
	321: 29,
	356: 30,
	394: 31,
	435: 32,
	479: 33,
	526: 34,
	576: 35,
	629: 36,
	685: 37,
	744: 38,
	806: 39,
	872: 40,
	942: 41,
	1016: 42,
	1094: 43,
	1176: 44,
	1262: 45,
	1352: 46,
	1446: 47,
	1544: 48,
	1646: 49,
	1753: 50,
	1865: 51,
	1982: 52,
	2104: 53,
	2231: 54,
	2363: 55,
	2500: 56,
	2642: 57,
	2789: 58,
	2941: 59,
	3099: 60,
	3263: 61,
	3433: 62,
	3609: 63,
	3791: 64,
	3979: 65,
	4173: 66,
	4373: 67,
	4579: 68,
	4791: 69,
	5010: 70,
	5236: 71,
	5469: 72,
	5709: 73,
	5956: 74,
	6210: 75,
	6471: 76,
	6739: 77,
	7014: 78,
	7296: 79,
	7586: 80,
	7884: 81,
	8190: 82,
	8504: 83,
	8826: 84,
	9156: 85,
	9494: 86,
	9840: 87,
	10194: 88,
	10556: 89,
	10927: 90,
	11307: 91,
	11696: 92,
	12094: 93,
	12501: 94,
	12917: 95,
	13342: 96,
	13776: 97,
	14219: 98,
	14671: 99,
	15133: 100,
} as const;

export const convertXpToLvl = (xp: number): number => {
	let lvl = 0;
	for (let i in Object.keys(XP_TO_LVL)) {
		if (xp > Number(Object.keys(XP_TO_LVL).at(-1))) {
			lvl = Object.values(XP_TO_LVL).at(-1);
			break;
		}

		const currentThreshold = Number(Object.keys(XP_TO_LVL)[Number(i)]);

		if (xp > Number(currentThreshold)) continue;

		if (xp < Number(currentThreshold)) {
			lvl = Number(Object.values(XP_TO_LVL)[Number(i) - 1]);
			break;
		}

		if (xp === Number(currentThreshold)) {
			lvl = Number(Object.values(XP_TO_LVL)[Number(i)]);
			break;
		}
	}
	return lvl;
};

export const getAmountOfXpOfPreviousAndNextLvl = (xp: number): [number, number] => {
	let amountOfXp = 0;
	let amountOfXpToReachNextLvl = 0;
	for (let i in Object.keys(XP_TO_LVL)) {
		if (xp > Number(Object.keys(XP_TO_LVL).at(-1))) {
			amountOfXp = 1;
			amountOfXpToReachNextLvl = 0;
			break;
		}
		const currentThreshold = Number(Object.keys(XP_TO_LVL)[Number(i)]);

		if (xp > Number(currentThreshold)) continue;

		if (xp < Number(currentThreshold)) {
			amountOfXp = xp - Number(Object.keys(XP_TO_LVL)[Number(i) - 1]);
			amountOfXpToReachNextLvl = Number(currentThreshold) - xp;
			break;
		}

		if (xp === Number(currentThreshold)) {
			// last lvl
			if (isNaN(Number(Object.keys(XP_TO_LVL)[Number(i) + 1]))) {
				amountOfXp = 1;
				amountOfXpToReachNextLvl = 0;
			} else {
				amountOfXp = 0;
				amountOfXpToReachNextLvl = Number(Object.keys(XP_TO_LVL)[Number(i) + 1]) - xp;
			}
			break;
		}
	}
	return [amountOfXp, amountOfXpToReachNextLvl];
};
