import { Grid, Typography } from "@mui/material";
import "../../../styles/home/FomoSection.scss";
import { useTranslation } from "react-i18next";

export const FomoSection = () => {
	const { t } = useTranslation('landing')
	const handleClick = () => {
		document.getElementById('landing-signup-container').scrollIntoView({ behavior: 'smooth' });
	}
	return (
		<Grid container justifyContent="center" my="150px">
			<Grid item xs={11} sm={9} xl={7} display="flex" flexDirection="column">
				<Typography variant="h4" className="fomo-text" mb={3}>
					{t('fomo.text')}
				</Typography>
				<button onClick={handleClick} className="button-landing" style={{ width: "fit-content" }}>
					{t('button.join.us')}
				</button>
			</Grid>
		</Grid>
	);
};
