import { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip as TooltipBootstrap } from "react-bootstrap";
import "../../styles/GamerBox.scss";
import { FirebaseContext } from "../Providers/FirebaseProvider";
import { Chip, Skeleton, Stack, Tooltip, TooltipProps, Typography, tooltipClasses } from "@mui/material";
import { AuthContext } from "../Providers/AuthProvider";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import { Box } from "@mui/material";
import { CircleImage } from "../Images/CircleImage";
import { GamesContext } from "../Providers/GamesProviderImpl";
import { StyledBadgeNoPulse } from "../Badges/StyledBedge";
import { styled as MUIStyled } from "@mui/material/styles";
import styled from "styled-components";
import {
	BASE_REWARD_PER_PERCENTILES,
	MAX_REWARD_PER_PERCENTILES,
	PERCENTILES,
	convertXpToLvl,
	gamesInStorm,
	getAmountOfXpOfPreviousAndNextLvl,
} from "../../utilities/costants/genericCostants";

const MIN_MULTIPLIER = (MAX_REWARD_PER_PERCENTILES[PERCENTILES.TOP15] - BASE_REWARD_PER_PERCENTILES[PERCENTILES.TOP15]) / 100;
const MAX_MULTIPLIER = (MAX_REWARD_PER_PERCENTILES[PERCENTILES.TOP01] - BASE_REWARD_PER_PERCENTILES[PERCENTILES.TOP01]) / 100;

const skeletonStyle = {
	backgroundColor: "white",
	opacity: "70%",
};

const StormText = styled.span`
	color: transparent;
	background-image: linear-gradient(90deg, #ab00c7 0.58%, #0075ff 97.84%);
	background-clip: text;
	-webkit-background-clip: text;
	fontweight: 900;
`;

export const GamerBox = (): JSX.Element => {
	const { t } = useTranslation("dashboard");
	const { myStorageUrl, storagePicturesUrl } = useContext(FirebaseContext);
	const { getGameSrcByName } = useContext(GamesContext);
	const { profile } = useContext(AuthContext);

	const [xpProgress, setXpProgress] = useState<number>(0);
	const [maxXP, setMaxXP] = useState<number>(0);

	useEffect(() => {
		if (profile === null || profile === undefined) return;

		const [prevXP, nextXP] = getAmountOfXpOfPreviousAndNextLvl(profile.stormXP ?? 0);
		setXpProgress(prevXP);
		setMaxXP(prevXP + nextXP);
	}, [profile]);

	const CustomTooltip = MUIStyled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "black",
			color: "white",
			maxWidth: 500,
			fontSize: "calc(.4rem + .6vw)",
		},
	}));

	const XPInformation = (
		<Box component="div" p={1} fontFamily="Sora" fontWeight={500}>
			<span>
				{t("gamerbox.xp.progress.info#1")} <StormText>{t("gamerbox.xp.progress.info#2")}</StormText> {t("gamerbox.xp.progress.info#3")}{" "}
				{t("gamerbox.xp.progress.info#4")}
			</span>
			<br />
			<br />
			<Box component="div" display="flex" flexDirection="column" alignItems="flex-start" fontWeight={100}>
				<span>
					{t("gamerbox.xp.progress.current.level")}: {convertXpToLvl(profile.stormXP ?? 0)}
				</span>
				<span>
					{t("gamerbox.xp.progress.current.multiplier")}: {t("gamerbox.xp.progress.current.multiplier#1")} <i>{MIN_MULTIPLIER.toFixed(6)}x lv.</i>{" "}
					{t("gamerbox.xp.progress.current.multiplier#2")} <i>{MAX_MULTIPLIER.toFixed(6)}x lv.</i>
				</span>
			</Box>
		</Box>
	);

	return (
		<>
			<div className="gamer-box">
				<div className="col-md-12">
					<div className="row">
						<div className="gamer-box-container">
							<div className="" style={{ position: "relative" }}>
								{profile != undefined && profile.imageUrl !== undefined ? (
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "100px",
											height: "100%",
										}}>
										{profile.favouriteGame != null ? (
											<StyledBadgeNoPulse
												overlap="circular"
												anchorOrigin={{ vertical: "top", horizontal: "right" }}
												badgeContent={
													<CircleImage src={`${myStorageUrl}${getGameSrcByName(profile.favouriteGame)}`} width={30} height={30} alt="game-logo" />
												}>
												<CircleImage width={90} height={90} src={`${storagePicturesUrl}${profile.imageUrl}`} alt="user-image" />
											</StyledBadgeNoPulse>
										) : (
											<CircleImage width={90} height={90} src={`${storagePicturesUrl}${profile.imageUrl}`} alt="user-image" />
										)}
									</Box>
								) : (
									<Skeleton
										data-testid="imageurl-skeleton"
										sx={{ ...skeletonStyle, height: "100%" }}
										variant={"circular"}
										width={"110px"}
										height={"110px"}></Skeleton>
								)}
							</div>
							<div className="gamer-box-wrapper">
								<div className="gamer-box-content">
									{profile != null && profile.username !== undefined ? (
										<label id="username-gamerbox">{profile.username}</label>
									) : (
										<Skeleton data-testid={"username-skeleton"} sx={skeletonStyle} width={"100%"}>
											<label></label>
										</Skeleton>
									)}

									<Stack direction={"column"}>
										{profile != null && profile.bolts !== undefined ? (
											<label>
												<img src={`${myStorageUrl}images/bolt_sign.png`} id="bolt-sign-gamerbox" className="bolt-sign" alt="bolt_sign" />
												{profile.bolts.toFixed(6)}
											</label>
										) : (
											<Skeleton data-testid="bolts-skeleton" sx={skeletonStyle} width={"100%"}></Skeleton>
										)}
										{profile != null && (
											<CustomTooltip placement="bottom" title={XPInformation}>
												<Box
													component="div"
													display="flex"
													alignItems="center"
													justifyContent="flex-start"
													gap={2}
													width="100%"
													sx={{ cursor: "help" }}>
													<Box
														component="progress"
														value={xpProgress}
														max={maxXP}
														width="100%"
														bgcolor="rgba(0, 0, 0, .6)"
														border="none"
														borderRadius="100px"
														mb="5px"
														sx={{
															"::-moz-progress-bar": {
																backgroundImage: "linear-gradient(90deg, #AB00C7 0.58%, #0075FF 97.84%)",
																opacity: ".6",
																borderRadius: "100px",
															},
															"::-webkit-progress-bar": {
																backgroundImage: "linear-gradient(90deg, #AB00C7 0.58%, #0075FF 97.84%)",
																opacity: ".6",
																borderRadius: "100px",
															},
														}}></Box>
													<Box component="label" width="80px">
														lvl. {convertXpToLvl(profile.stormXP) ?? 0}
													</Box>
												</Box>
											</CustomTooltip>
										)}
										{profile.freezedBolts !== undefined && (
											<OverlayTrigger
												placement={"bottom"}
												trigger={["hover", "click"]}
												overlay={
													<TooltipBootstrap
														style={{
															background: "rgba(255, 255, 255, .1)",
															paddingLeft: ".4em",
															paddingRight: ".4em",
															borderRadius: "5px",
														}}></TooltipBootstrap>
												}>
												<label>
													<img src={`${myStorageUrl}images/bolt_sign.png`} id="freezed-bolt-sign-gamerbox" className="bolt-sign" alt="bolt_sign" />
													<label id="freezed-bolts-amount">{profile.freezedBolts.toFixed(1)}</label>
												</label>
											</OverlayTrigger>
										)}
										{profile != null && profile.isPremium && <Chip style={{ width: "30%" }} label="Premium" color="secondary" />}
									</Stack>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="gamer-box-header-mobile-bolts">
				{profile != null && profile.bolts !== undefined ? (
					<>
						<p className="text-cta">
							<img src={`${myStorageUrl}images/bolt_sign.png`} id="bolt-sign-gamerbox" className="bolt-sign" alt="bolt_sign" />
							{profile.bolts.toFixed(1)}
						</p>
						<CustomTooltip placement="bottom" title={XPInformation}>
							<Box
								component="div"
								position="absolute"
								bottom={5}
								display="flex"
								alignItems="center"
								justifyContent="flex-start"
								gap={2}
								width="100%"
								px={2}
								sx={{ cursor: "help" }}>
								<Box
									component="div"
									width="100%"
									position="relative"
									sx={{
										"&::before": {
											content: `'lvl. ${convertXpToLvl(profile.stormXP) ?? 0}'`,
											width: "100%",
											position: "absolute",
											color: "white",
											zIndex: 999,
										},
									}}>
									<Box
										component="progress"
										value={xpProgress}
										max={maxXP}
										width="100%"
										height="20px"
										bgcolor="rgba(0, 0, 0, .6)"
										border="1px solid rgba(255, 255, 255, .3)"
										borderRadius="100px"
										position="relative"
										sx={{
											"::-moz-progress-bar": {
												backgroundImage: "linear-gradient(90deg, #AB00C7 0.58%, #0075FF 97.84%)",
												opacity: ".6",
												borderRadius: "100px",
											},
											"::-webkit-progress-bar": {
												backgroundImage: "linear-gradient(90deg, #AB00C7 0.58%, #0075FF 97.84%)",
												opacity: ".6",
												borderRadius: "100px",
											},
										}}></Box>
								</Box>
							</Box>
						</CustomTooltip>
					</>
				) : (
					<Skeleton data-testid="bolts-skeleton" sx={skeletonStyle} width={"100%"}></Skeleton>
				)}
			</div>
		</>
	);
};
