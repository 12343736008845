import { useContext, useEffect, useState } from "react";
import { Leaderboard } from "./Leaderboard";
import Countdown from "react-countdown";
import { Box, Grid, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LeaderboardContext } from "../../../Providers/LeaderboardProvider";
import { makeStyles } from "@mui/styles";
import { AuthContext } from "../../../Providers/AuthProvider";
import { LeaderboardsContainerProps } from "../../../../types/Props";
import { gamesInStorm } from "../../../../utilities/costants/genericCostants";
import { SettingsContext } from "../../../Providers/SettingsProvider";

export const LeaderboardsContainer = (props: LeaderboardsContainerProps): JSX.Element => {
	const { numberOfPlayersFortnite, numberOfPlayersDota2, standardLeaderboard } = useContext(LeaderboardContext);
	const { changeActiveDashboard } = useContext(SettingsContext);

	const { profile } = useContext(AuthContext);
	const [currentGameStats, setCurrentGameStats] = useState(0);
	const [distributionDate, setDistributionDate] = useState(0);
	const { t } = useTranslation("dashboard");

	const { fetchLeaderboard } = useContext(LeaderboardContext);

	const stylesForPagination = makeStyles(() => ({
		root: {
			"& li .MuiPaginationItem-root": {
				color: "#fff",
			},
		},
	}));

	useEffect(() => {
		timerDristribution();
	}, []);

	useEffect(() => {
		if (profile.favouriteGame === gamesInStorm.fortnite) {
			setCurrentGameStats(numberOfPlayersFortnite);
			return;
		} else if (profile.favouriteGame === gamesInStorm.dota) {
			setCurrentGameStats(numberOfPlayersDota2);
			return;
		} else {
			setCurrentGameStats(numberOfPlayersDota2);
		}
	}, [profile.favouriteGame, numberOfPlayersFortnite, numberOfPlayersDota2]);

	const changeStatsRendered = (game: string): void => {
		if (game !== gamesInStorm.dota && game !== gamesInStorm.fortnite) {
			console.error("An invalid game was passed in changeStatsRendered function");
			return;
		}

		switch (game) {
			case gamesInStorm.dota:
				setCurrentGameStats(numberOfPlayersDota2);
				break;
			case gamesInStorm.fortnite:
				setCurrentGameStats(numberOfPlayersFortnite);
				break;
			default:
				break;
		}
	};

	function timerDristribution(): void {
		const date = new Date();

		if (date.getUTCHours() == 24 && date.getUTCMinutes() > 30) {
			date.setUTCDate(date.getUTCDate() + 1);
		}
		date.setUTCHours(24, 30, 0, 0);
		const UTCTimeNow = new Date();

		const now_utc = Date.UTC(
			UTCTimeNow.getUTCFullYear(),
			UTCTimeNow.getUTCMonth(),
			UTCTimeNow.getUTCDate(),
			UTCTimeNow.getUTCHours(),
			UTCTimeNow.getUTCMinutes(),
			UTCTimeNow.getUTCSeconds()
		);
		const diffeerence = date.getTime() - now_utc;
		setDistributionDate(diffeerence);
	}

	const loadPlayers = async (startPos: number, game: string, liveLeaderboard: boolean): Promise<void> => {
		if (startPos < 0) {
			startPos = 0;
		}
		if (startPos !== 0) {
			startPos++;
		}
		await fetchLeaderboard(startPos, game, liveLeaderboard);
	};
	return (
		<>
			<Box width={"100%"}>
				<Box className="leaderboard-classic text-center">
					{standardLeaderboard.dota2 === undefined && standardLeaderboard.fortnite === undefined ? (
						<Skeleton variant={"rectangular"} width={"100%"} height={"200px"} sx={{ bgcolor: "grey.900" }}></Skeleton>
					) : (
						<>
							{!props.isLiveLeaderboard && (
								<>
									<Grid display={"flex"} justifyContent={"space-between"}>
										{currentGameStats === undefined ? (
											<Skeleton variant={"rectangular"} width={"100%"} sx={{ bgcolor: "grey.900" }} />
										) : (
											<div>
												<h3>{t("leaderboard")}</h3>
											</div>
										)}
									</Grid>
									<hr className="border-line" />
								</>
							)}
							{props.isLiveLeaderboard === true && !profile != null && profile.isPremium && (
								<Grid
									item
									xs={8}
									sx={{ padding: "1em", borderRadius: "16px", borderColor: "white", border: "1px solid", fontSize: "14px" }}
									className="unlock-premium-features">
									<a
										className="text-p no-game-connected-text"
										onClick={() => {
											changeActiveDashboard("market");
										}}>
										{t("upgrade to premium")}
									</a>
								</Grid>
							)}
							<Leaderboard
								style={stylesForPagination}
								changeStatsRendered={changeStatsRendered}
								loadPlayers={loadPlayers}
								isLiveLeaderboard={props.isLiveLeaderboard}
							/>
						</>
					)}
				</Box>
				{!props.isLiveLeaderboard && (
					<>
						<Box className="leaderboard-player-and-distribution ">
							<Grid container justifyContent={"space-between"}>
								<Grid item xs={6} sx={{ paddingLeft: "1rem" }} className="total-daily-players-container">
									{t("total daily players")} {currentGameStats}
								</Grid>
								<Grid item xs={6} sx={{ paddingRight: "1rem" }} className="timer-distribution-container">
									<div className="timerDistribution">
										<p>{t("time until distribution")}</p>
										{distributionDate != 0 && <Countdown date={Date.now() + distributionDate} />}
									</div>
								</Grid>
							</Grid>
						</Box>
					</>
				)}
			</Box>
		</>
	);
};
