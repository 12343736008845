import config from "../../config-file.json";

export let url = config[process.env.REACT_APP_ENV]["url-backend"];

/* >>>>>>>>>>>>> HEADER API REQUEST >>>>>>>>>>>>> */
export const headerAPI = {
	headers: {
		withCredentials: true,
	},
};

export let signupAPI = `/auth/createUser`;
export let validateSignupAPI = `/auth/validateSignUpData`;
export let validateCodeAPI = `/users/promocodes`;

export let steamAuthAPI = `/auth/steam`;
export let changeUsernameAPI = `/users/username/change`;
export let fortniteAuthAPI = `/auth/epicGames/fortnite`;
export const createCheckoutAPI = `/stripe/createCheckoutSession`;

export let uploadImageAPI = `/users/uploadImage`;
export let handleUserPromoCodeAPI = `/users/promocodes`;
export let leaderboardAPI = `/leaderboard`;

export let skipTutorialAPI = `/users/skipTutorial`;
export let paymentUserAPI = `/users/paymentMajor`;
export let updateFavouriteGameAPI = `/users/updateFavouriteGame`;

export let paypalOrderAPI = `/paypal/order`;
export let paypalOrderApprovedAPI = `/paypal/order/approved`;

export let historyPlacemtesAPI = `/fetchLeaderboardHistory`;

export let didUserPayAnItemFullPriceAPI = `/orders/hasBoughtItemFullPrice`;

export module PaymentsAPI {
	const url = "/payments";

	export const subscription = `${url}/subscription`;
	export const reward = `${url}/reward`;
}

export module UserAPI {
	const baseUrl = "/users";

	export module Admin {
		const url = "/admin";

		export const addPremium = `${baseUrl}${url}/addPremium`;
		export const removePremium = `${baseUrl}${url}/removePremium`;
	}
}

export module InventoryAPI {
	const baseUrl = "/inventory";

	export module Admin {
		const url = "/admin";

		export const addRewardItem = `${baseUrl}${url}/addRewardItem`;
		export const removeRewardItem = `${baseUrl}${url}/removeRewardItem`;
		export const editRewardItem = `${baseUrl}${url}/editRewardItem`;
	}
}
