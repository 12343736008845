import { Stack, Typography } from "@mui/material";
import GradientTypographyBoxTitle from "../../Typographies/GradientTypographyBoxTitle";
import { useTranslation } from "react-i18next";

export const ContactUsBox = () => {
	const { t } = useTranslation("profilepage");

	return (
		<Stack
			spacing={2}
			direction={"column"}
			padding={"1em"}
			justifyContent={"center"}
			sx={{
				background: "black",
				borderRadius: "20px",
			}}>
			<GradientTypographyBoxTitle content={t("contact.us")} variant="h3" />
			<Typography variant={"caption"}>
				{t("contact.us#1")}{" "}
				<label
					style={{
						textDecoration: "underline",
						cursor: "pointer",
					}}
					onClick={() => {
						window.open("https://discord.gg/s32pk3Qt53", "_blank");
					}}>
					{" "}
					{t("contact.us#2")}
				</label>
			</Typography>
		</Stack>
	);
};
