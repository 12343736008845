import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../Providers/AuthProvider";
import { NavbarStorm } from "../NavbarStorm";
import { SettingsContext } from "../Providers/SettingsProvider";
import { DashboardAdmin } from "./AdminComponents/DashboardAdmin";
import { DashboardGameHub } from "./Gamehub/DashboardGameHub";
import { DashboardHome } from "./DashboardHome";
import { DashboardMarket } from "./DashboardMarket";
import { DashboardProfile } from "./Profile/DashboardProfile";
import SwitchDashboardsComponents from "./SwitchDashboardsComponents";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import { TutorialOverlay } from "../Tutorial/Tutorial";
import { GenericProps } from "../../types/Props";
import "../../styles/Dashboard.scss";
import "../../styles/Gamehub.scss";
import "../../styles/DashboardLeaderBoard.scss";

const DashboardRoot = (props: GenericProps) => {
	const { activeDashboard } = useContext(SettingsContext);
	const { lastNotification, profile, connectEpicGames } = useContext(AuthContext);
	const [role, setRole] = useState("");
	const search = useLocation().search;

	useEffect(() => {
		if (lastNotification) {
			if ($(document).width() < 900) {
				const { message, time } = lastNotification;
				const { date, hours, minutes } = time;

				const stringD = date !== 0 ? `${date}d ` : "";
				const stringH = hours !== 0 ? `${hours}h ` : "";
				const stringM = minutes !== 0 ? `${minutes}m ` : "";

				toast.success(`${message} \n ${stringD === "" && stringH === "" && stringM === "" ? "now" : `${stringD + stringH + stringM} ago`}`, {
					style: {
						// border: '1px solid var(--blue)',
						border: "1px solid white",
						borderRadius: "10px",
						backgroundImage: "linear-gradient(90deg, #AB00C7 0.58%, #0075FF 97.84%)",
						// backgroundOrigin: "border-box",
						backgroundClip: "border-box",
						padding: "16px",
						color: "var(--white)",
						zIndex: 300,
					},
					iconTheme: {
						primary: "var(--white)",
						secondary: "var(--blue)",
					},
					duration: 6000,
				});
			}
		}
	}, [lastNotification]);

	const handleEpicGameConnection = async (): Promise<void> => {
		if (profile !== null && profile !== undefined) {
			setRole(profile.role);
		}
		try {
			let code = new URLSearchParams(search).get("code");

			if (code == null || code.length != 32) throw new Error("unknown code");

			await connectEpicGames(code);
		} catch (e) {
			console.log(`No epic games code found in url`);
		}
	};

	useEffect(() => {
		if (profile) handleEpicGameConnection();
	}, [profile]);

	const isTutorial = () => {
		if (profile != null) {
			return (
				profile.tutorial === undefined &&
				(profile.steamId === "" || profile.steamId === undefined) &&
				(profile.epicId === "" || profile.epicId === undefined)
			);
		}
	};

	return (
		<>
			<NavbarStorm role={role} isTutorial={isTutorial()} showToast={props.showToast} />

			{isTutorial() && <TutorialOverlay />}

			<SwitchDashboardsComponents active={activeDashboard}>
				<DashboardHome name="home" showToast={props.showToast} isTutorial={isTutorial()} />
				<DashboardMarket name="market" />
				<DashboardGameHub name="gamehub" showToast={props.showToast} isTutorial={isTutorial()} />
				<DashboardProfile name="profile" showToast={props.showToast} />
				<DashboardAdmin name="statsDashboard" />
			</SwitchDashboardsComponents>
		</>
	);
};

export default DashboardRoot;
