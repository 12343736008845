import { createContext, useContext, useEffect, useState } from "react";
import { WalletProvider } from "../../types/Providers";
import { MetaMaskInpageProvider } from "@metamask/providers";
import { Buffer } from "buffer";
import { SnackbarContext } from "./SnackbarProvider";
import { useTranslation } from "react-i18next";

declare global {
    interface Window {
        ethereum?: MetaMaskInpageProvider
    }
}

export const WalletContext = createContext<WalletProvider | null>(null);
export const WalletProviderImpl = (props): JSX.Element => {
    const children = props.children;
    const {t} = useTranslation(['dasboard'])

    const connect = async () => {
        let address = '';
        if (typeof window.ethereum !== 'undefined') {
            const ethereum = window.ethereum;
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            address = accounts[0]
        } else {
            throw new Error(t('metamask.not.installed'));            
        }
        return address;
    }

    // if address is not passed then the address of the current user logged is used
    const signMessage = async (addr) => {
        const messageToSign = 'Viva la vida';
        const ethereum = window.ethereum;
        const from = addr;
        const msg = `0x${Buffer.from(messageToSign, 'utf8').toString('hex')}`;
        const sign = await ethereum.request({
            method: 'personal_sign',
            params: [msg, from, 'example psw']
        })
        return sign;
    }

    const walletProviderData: WalletProvider = {
        connect,
        signMessage,
    };

    return <WalletContext.Provider value={walletProviderData}>{children}</WalletContext.Provider>;
};
