import { Button, Grid } from "@mui/material";
import GradientTypographyBoxTitle from "../../Typographies/GradientTypographyBoxTitle";
import { useContext } from "react";
import { AuthContext } from "../../Providers/AuthProvider";
import { CircleImage } from "../../Images/CircleImage";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { GamesContext } from "../../Providers/GamesProviderImpl";
import CachedIcon from "@mui/icons-material/Cached";
import { SettingsContext } from "../../Providers/SettingsProvider";
import { OutlinedButtonV1 } from "../../Buttons/CustomOutlinedButtonV1";
import { useTranslation } from "react-i18next";
import { StyledBadgePulse } from "../../Badges/StyledBedge";
import { gamesInStorm } from "../../../utilities/costants/genericCostants";

export const FavouriteGameBox = () => {
	const { changeActiveDashboard } = useContext(SettingsContext);
	const { profile } = useContext(AuthContext);
	const { myStorageUrl } = useContext(FirebaseContext);
	const { getGameSrcByName } = useContext(GamesContext);
	const { t } = useTranslation("profilepage");

	return (
		<Grid container>
			<Grid item xs={12} sx={{ padding: "1em" }}>
				<GradientTypographyBoxTitle content={t("favourite.game")} variant="h3" />
			</Grid>
			<Grid
				item
				xs={12}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}>
				{profile.favouriteGame == null ? (
					<OutlinedButtonV1
						content={t("no.favourite.game")}
						onClick={() => changeActiveDashboard("gamehub")}
						sx={{
							fontSize: "10px",
							whiteSpace: "nowrap",
						}}
					/>
				) : (
					<Button component="label" onClick={() => changeActiveDashboard("gamehub")}>
						<StyledBadgePulse
							overlap="circular"
							anchorOrigin={{ vertical: "top", horizontal: "right" }}
							badgeContent={<CachedIcon sx={{ color: "white" }} />}>
							<CircleImage width={100} height={100} src={`${myStorageUrl}${getGameSrcByName(profile.favouriteGame)}`} alt="profile-change" />
						</StyledBadgePulse>
					</Button>
				)}
			</Grid>
		</Grid>
	);
};
