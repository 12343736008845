import React, { useContext, useEffect } from "react";
import $ from "jquery";
import { useState } from "react";
import { InputText } from "../Input/InputText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { isEmail, isPassword, isEmpty } from "./Validation";
import { ThirdPartyAuthSignIn } from "./ThirdPartyAuthSignIn";
import { SettingsContext } from "../Providers/SettingsProvider";
import { AuthContext } from "../Providers/AuthProvider";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "../Providers/FirebaseProvider";
import { GenericProps } from "../../types/Props";
import { UserSingInEmailAndPassword } from "../../types/User";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CheckPolicies } from "./CheckPolicies";
import { PixelsContext } from "../Providers/PixelsProvider";
import { Tooltip } from "@mui/material";
import { getTermsAndConditions } from "../../utilities/policies/getPrivacyPoliciesLinks";


export const SignupForm = (props: GenericProps): JSX.Element => {
	const { checkIfUsernameIsTaken, validateCode, register } = useContext(AuthContext);
	const { emitPixelEvent, PIXEL_EVENTS } = useContext(PixelsContext);
	const [loading, setLoading] = React.useState(false);
	const [userAcceptedPrivacy, setUserAcceptedPrivacy] = useState(false);
	const [userAcceptedClauses, setUserAcceptedClauses] = useState(false);
	const [userAcceptedTerms, setUserAcceptedTerms] = useState(false);
	const { language } = useContext(SettingsContext);
	const { storagePicturesUrl } = useContext(FirebaseContext);
	const { myStorageUrl } = useContext(FirebaseContext);
	const { t } = useTranslation("dashboard");
	const [loadingGoogleLogIn, setLoadingGoogleLogIn] = useState(false);

	const handleButtonClick = (status: boolean): void => {
		setLoading(status);
	};
	const isDataValid = (data): boolean => {
		let valid = true;
		if (data.username === undefined || data.email === undefined || data.password === undefined || data.confirmPassword === undefined) {
			props.showToast(t("please fill all the form"), "Error");
			return;
		}

		if (data.username.trim().length < 5) {
			showErrorInField("#errUsername", t("username must contain at least 5 characters"));
			valid = false;
		} else {
			showSuccessInField("#errUsername");
		}

		if (data.email != data.confirmEmail) {
			showErrorInField("#errConfirmEmail", t("emails must match"));
			valid = false;
		} else {
			showSuccessInField("#errConfirmEmail");
		}

		if (data.password != data.confirmPassword && data.password !== "" && data.confirmPassword !== "") {
			showErrorInField("#errConfirmPassword", t("passwords must match"));
			valid = false;
		} else {
			showSuccessInField("#errConfirmPassword");
		}

		if (!isPassword(data.confirmPassword) || isEmpty(data.confirmPassword)) {
			showErrorInField("#errConfirmPassword", t("passwords must meet the requirements"));
			valid = false;
		} else {
			showSuccessInField("#errConfirmPassword");
		}

		if (!isPassword(data.password)) {
			showErrorInField("#errPassword", t("passwords must meet the requirements"));
			valid = false;
		} else {
			showSuccessInField("#errPassword");
		}

		if (!isEmail(data.email) || isEmpty(data.email)) {
			showErrorInField("#errEmail", t("email is not formatted correctly"));
			valid = false;
		} else {
			showSuccessInField("#errEmail");
		}

		if (!isEmail(data.confirmEmail) || isEmpty(data.confirmEmail)) {
			showErrorInField("#errConfirmEmail", t("email is not formatted correctly"));
		}

		return valid;
	};

	const handleRegistration = async (): Promise<void> => {
		emitPixelEvent(PIXEL_EVENTS.COMPLETE_REGISTRATION, {});
		handleButtonClick(true);
		["#errConfirmEmail", "#errUsername", "#errEmail", "#errPassword", "#errConfirmPassword", "#errCodice"].forEach((id) => {
			$(id).parent().removeClass("error");
			$(id).text("");
		});

		const usernameValue = $("#txtUsername").val() !== undefined ? ($("#txtUsername").val() as string).trim() : "";
		let body: UserSingInEmailAndPassword = {
			username: usernameValue,
			email: $("#txtEmail").val() as string,
			confirmEmail: $("#txtConfirmEmail").val() as string,
			password: $("#txtPassword").val() as string,
			confirmPassword: $("#txtConfirmPassword").val() as string,
			promoCode: $("#txtCodice").val() as string,
		};

		if (!userAcceptedPrivacy) {
			handleButtonClick(false);
			props.showToast(t("you must accept our privacy policy"), "Warning");
			return;
		}
		if (!userAcceptedClauses) {
			handleButtonClick(false);
			props.showToast(t("accept.clauses.auth.warning"), "Warning");
			return;
		}

		if(!userAcceptedTerms) {
			handleButtonClick(false);
			props.showToast(t("accept.terms.warning"), "Warning");
			return;
		}

		if (!isDataValid(body)) {
			setLoading(false);
			handleButtonClick(false);
			return;
		}
		try {
			const usernameIsTaken = await checkIfUsernameIsTaken(body.username);
			if (usernameIsTaken) {
				showErrorInField("#errUsername", t("username already taken"));
				props.showToast(t("username already taken"), "Error");
				return;
			}
			if (body.promoCode !== "") {
				let { isCodeValid, message } = await validateCode(body.promoCode);

				if (!isCodeValid) {
					showErrorInField("#errCodice", message);
					props.showToast(message, "Error");
					return;
				}
			}
			const userRegistered = await register(body);

			if (userRegistered) {
				emitPixelEvent(PIXEL_EVENTS.COMPLETE_REGISTRATION, {});
				props.showToast(t("cookieAlertSignupPage"), "Info");
				window.location.href = "/dashboard";
			} else {
				throw { message: "Something went wrong while creating new user" };
			}
		} catch (error) {
			console.log(error);
			if (error.message === "Network Error") window.location.href = "/networkError";

			if (error.code === "auth/email-already-in-use") {
				props.showToast(t("email already used"), "Error");
				showErrorInField("#errEmail", t("email already used"));
			}

			if (error.message === "Something went wrong while creating new user") {
				props.showToast("something went wrong", "Error");
			}
		}
	};

	const showErrorInField = (id: string, message: string): void => {
		$(id).parent().removeClass("success");
		$(id).parent().addClass("error");
		$(id).css("opacity", "1");
		$(id).text(message);
		setLoading(false);
	};

	const showSuccessInField = (id: string): void => {
		$(id).parent().removeClass("error");
		$(id).parent().addClass("success");
	};

	function handleCheckPolicies(event: React.ChangeEvent<HTMLInputElement>): void {
		setUserAcceptedPrivacy(event.target.checked);
	}

	useEffect(() => {
		$("body")
			.off()
			.on("keypress", function (e: JQuery.Event) {
				singupEnter(e);
			});
	});

	function singupEnter(e: JQuery.Event): void {
		let username = ($("#txtUsername").val() as string).trim();
		let email = $("#txtEmail").val();
		let password = $("#txtPassword").val();
		let confirmPassword = $("#txtConfirmPassword").val();
		let codice = $("#txtCodice").val();
		if (e.key === "Enter" && (username != "" || email != "" || password != "" || confirmPassword != "" || codice != "")) {
			handleRegistration();
		}
	}

	return (
		<>
			<div className="singup-background"></div>
			<div className="signup" id="singup">
				<div className="signup-main">
					<div className="d-flex w-100">
						<div className="signup-box">
							<a href="/">
								<img src={`${myStorageUrl}vectors/STORM_logo.svg`} id={"storm-logo-signup-form"} alt="image" />
							</a>

							<h1>{t("sign up")}</h1>

							<hr />

							<InputText type="text" id="txtUsername" idErr="errUsername" placeholder={t("username placeholder")} />
							<InputText type="email" id="txtEmail" idErr="errEmail" placeholder={t("email placeholder")} />
							<InputText type="email" id="txtConfirmEmail" idErr="errConfirmEmail" placeholder={t("email confirm placeholder")} />

							<div className="passwordContainer">
								<InputText type="password" id="txtPassword" idErr="errPassword" placeholder={t("password placeholder")} />
								<FontAwesomeIcon icon={faCircleQuestion as IconProp} className="fa-xl passwordInfo" />
								<div className="passwordOverlay">
									<p className="text-label">
										{t("minimum lenght")} <b>{t("8 char")}</b>
										<br />
										<br />
										{t("must contain")} <br />
									</p>
									<ul className="text-label">
										{t("1 lowercase char")} <br />
										{t("1 uppercase char")} <br />
										{t("1 number")} <br />
										{t("1 special char")}
									</ul>
								</div>
							</div>

							<InputText
								type="password"
								id="txtConfirmPassword"
								idErr="errConfirmPassword"
								placeholder={t("confirm password placeholder")}
							/>
							<InputText type="text" id="txtCodice" idErr="errCodice" placeholder={t("promo code placeholder")} />
							<CheckPolicies onChange={(event) => handleCheckPolicies(event)} />
							<label className="error" id="errCheckboxNotChecked"></label>
							<br/>
							<label id={"confirm-privacy-policies-text"}>
								{t("i accept the")}
								<a className={"privacy-policies-link"} href={`${storagePicturesUrl}privacy_policies/${getTermsAndConditions()}`} target="_blank" rel="noreferrer" title="Terms and conditions">
									{t("terms.and.conditions")}
								</a>
							</label>
							<label className="checkbox" style={{ opacity: 1, background: 0 }}>
								<input type="checkbox" id={"confirm-privacy-policies-checkbox"} name={"confirm-privacy-policies-checkbox"} onChange={() => {setUserAcceptedTerms(!userAcceptedTerms)}} />
								<span></span>
							</label>

							<br />
							<Tooltip title={t('accept.clauses.content')}>
								<label id={"confirm-privacy-policies-text"}>
									<a className={"privacy-policies-link"} target="_blank" rel="noreferrer" title="Privacy Policy">
										{t("accept.clauses.auth")}
									</a>
								</label>
							</Tooltip>
							<label className="checkbox" style={{ opacity: 1, background: 0 }}>
								<input type="checkbox" id={"confirm-privacy-policies-checkbox"} name={"confirm-privacy-policies-checkbox"} onClick={() => {
									setUserAcceptedClauses(!userAcceptedClauses);
								}} />
								<span></span>
							</label>

							<br />

							<div className="buttons-singup">
								{loading ? (
									<CircularProgress
										size={24}
										sx={{
											color: grey[50],
											top: "50%",
											left: "50%",
											marginTop: "-12px",
											marginLeft: "-12px",
										}}
									/>
								) : (
									<button type="button" className="sign-up-btn btn-outline" onClick={handleRegistration}>
										{t("sign up")}
									</button>
								)}
								{loadingGoogleLogIn ? (
									<CircularProgress
										size={24}
										sx={{
											color: grey[50],
											top: "50%",
											left: "50%",
											marginBottom: "1em",
										}}
									/>
								) : (
									<>
										<ThirdPartyAuthSignIn
											showToast={props.showToast}
											language={language}
											auth="google"
											source="signup"
											privacyAccepted={userAcceptedPrivacy}
											setLoadingGoogleLogIn={setLoadingGoogleLogIn}
										/>
									</>
								)}
							</div>
							<label className="text-label">
								{t("do you have already an account?")}
								<a href="/login">{t("login")}</a>
							</label>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
