import { FormEvent, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../Providers/AuthProvider";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { useSnackbar } from "../../Providers/SnackbarProvider";
import { Button, Grid } from "@mui/material";
import GradientTypographyBoxTitle from "../../Typographies/GradientTypographyBoxTitle";
import { CircleImage } from "../../Images/CircleImage";
import CachedIcon from '@mui/icons-material/Cached';
import { StyledBadgePulse } from "../../Badges/StyledBedge";

export const ProfileImageBox = () => {
    const { profile, changeImage } = useContext(AuthContext);
    const { storagePicturesUrl } = useContext(FirebaseContext);
    const { openSnackbar } = useSnackbar();
    const { t } = useTranslation("dashboard");

    const changeUserImage = async (e: FormEvent<HTMLInputElement>): Promise<void> => {
        let fileImg = e.currentTarget.files[0];
        const imageName = fileImg.name.trim();
    
        if (!checkExtension(imageName)) {
            openSnackbar(t('error'), t("extension error"), "error");
            return;
        }
    
        const reader = new FileReader();
        reader.readAsDataURL(fileImg);
        reader.onload = async (event) => {
            const image = new Image();
            image.src = event.target.result as string; 
    
            image.onload = async () => {
                const canvas = document.createElement('canvas');
                const MAX_WIDTH = 300; 
                const MAX_HEIGHT = 300; 
    
                let width = image.width;
                let height = image.height;
    
                if (width > height && width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                } else if (height > width && height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
    
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, width, height);
    
                const resizedImage = await canvas.toDataURL('image/jpeg', 0.8);
    
                const blob = dataURItoBlob(resizedImage);
                const resizedFile = new File([blob], imageName, { type: 'image/jpeg' });
    
                const formData = new FormData();
                formData.append("image", resizedFile, imageName);
    
                try {
                    const message = await changeImage(formData);
                    openSnackbar(t('success'), message, "success");
                } catch (err) {
                    openSnackbar(t('error'), err.message, "error");
                }
            };
        };
    };

    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: mimeType });
        return blob;
    }

    const checkExtension = (imageName: string): boolean => {
        const enabledExtensions = [".png", ".jpg", ".jpeg", ".jfif", ".pjpeg", ".pjp"];
        const length = imageName.length;

        for (let ex of enabledExtensions) {
            const found = imageName.indexOf(ex); // check if extension is in the file name
            if (found != -1 && length - found == ex.length)
                // if it's in, check if in the right position
                return true;
        }

        return false;
    };

    return (
        <Grid container className="profile-upload">
            <Grid item xs={12} sx={{ padding: '1em' }}>
                <GradientTypographyBoxTitle content={t('profile.page.image')} variant="h3" />
            </Grid>
            <Grid item xs={12} style={{
                paddingBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Button component='label'>
                    <input
                        style={{ display: "none", cursor: 'pointer' }}
                        type="file"
                        onInput={changeUserImage}
                        accept="image/png, image/jpeg"
                        id="contained-button-file"
                    />
                    <StyledBadgePulse
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        badgeContent={<CachedIcon sx={{ color: 'white' }} />}
                    >
                        <CircleImage  width={140} height={140} src={`${storagePicturesUrl}${profile.imageUrl}`} alt="profile-change" />
                    </StyledBadgePulse>
                </Button>


            </Grid>
        </Grid>
    )
}