import { CollectionReference, collection, getDocs, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { UserDoc } from "../../../types/User";
import { UserContext } from "../../Providers/UserProvider";

type UserDocWithId = UserDoc & { id: string };

export const DashboardAdminPremiumUsers = () => {
	const { myFS } = useContext(FirebaseContext);
	const { handleAddPremiumToUser, handleRemovePremiumFromUser } = useContext(UserContext);

	const [show, setShow] = useState(false);
	const [premiumUsers, setPremiumUsers] = useState([] as UserDocWithId[]);
	const [viewPremiumUsers, setViewPremiumUsers] = useState([] as UserDocWithId[]);

	const retrieveAllPremiumUsers = async (): Promise<void> => {
		try {
			const docRef = query<UserDoc>(collection(myFS, "users") as CollectionReference<UserDoc>, where("premiumEndDate", "!=", ""));
			const querySnap = await getDocs<UserDoc>(docRef);
			let premiumUsers = [] as UserDocWithId[];

			querySnap.forEach((doc) => {
				premiumUsers.push({ ...doc.data(), id: doc.id } as UserDocWithId);
			});
			setPremiumUsers(premiumUsers);
			setViewPremiumUsers(premiumUsers);
			console.log(premiumUsers);
		} catch (error) {
			console.log(error);
		}
	};

	const handleAdd = async (email: string, premiumEndDateInUnixTime: number): Promise<void> => {
		await handleAddPremiumToUser(email, premiumEndDateInUnixTime);
		retrieveAllPremiumUsers();
	};

	const handleRemove = async (uid: string): Promise<void> => {
		await handleRemovePremiumFromUser(uid);
		setPremiumUsers((prev) => prev.filter((user) => user.id !== uid));
		setViewPremiumUsers(premiumUsers.filter((user) => user.id !== uid));
	};

	const handleSearch = (search: string): void => {
		const currentUsers = [...premiumUsers];

		if (search === "") {
			setViewPremiumUsers(currentUsers);
			return;
		}

		const filteredUsers = currentUsers.filter(
			(user) =>
				user.email.includes(search) ||
				user.id.includes(search) ||
				user.username.includes(search) ||
				(user.role !== undefined && user.role.includes(search))
		);
		setViewPremiumUsers(filteredUsers);
	};

	useEffect(() => {
		retrieveAllPremiumUsers();
	}, [myFS]);

	return (
		<>
			<button type="button" className="btn-no-fill" style={{ width: "20%" }} onClick={() => setShow(!show)}>
				<h3>{show ? "Hide" : "Show"} premium users</h3>
			</button>
			<div style={{ display: show ? "block" : "none" }}>
				<h1>Premium users</h1>
				<FormAddPremiumUser handleAddPremium={handleAdd} />
				<input style={{ width: "300px" }} type="text" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
				<div style={{ height: "500px", overflowY: "scroll" }}>
					<table>
						<thead>
							<tr>
								<th>Email</th>
								<th>Firebase UID</th>
								<th>Premium End Date</th>
								<th>Remove Premium</th>
							</tr>
						</thead>
						<tbody>
							{viewPremiumUsers.map((user) => {
								return (
									<tr key={user.id}>
										<td>{user.email}</td>
										<td>{user.id}</td>
										<td>{new Date(user.premiumEndDate * 1000).toUTCString()}</td>
										<td>
											<button onClick={async () => await handleRemove(user.id)}> X </button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

type FormAddPremiumUserProps = {
	handleAddPremium: (email: string, premiumEndDateInUnixTime: number) => Promise<void>;
};
const FormAddPremiumUser = ({ handleAddPremium }: FormAddPremiumUserProps) => {
	const [email, setEmail] = useState("");
	const [premiumEndDate, setPremiumEndDate] = useState<number>(0);

	return (
		<div>
			<input type="email" placeholder="User Email" onChange={(e) => setEmail(e.target.value)} />
			<input type="number" placeholder="Premium End Date" onChange={(e) => setPremiumEndDate(e.target.valueAsNumber)} />
			<button onClick={async () => await handleAddPremium(email, premiumEndDate)}>Add Premium</button>
		</div>
	);
};
