import { Grid, Typography } from "@mui/material";
import { SignupForm } from "./SignupForm";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "../../../../styles/home/Signup.scss";
import { HeroAnimation } from "../../../../Javascript/HeroAnimation";

export const SignupContainer = () => {
	const { t } = useTranslation("landing");
	useEffect(() => {
		HeroAnimation("#gradient-canvas-sign-up");
	}, []);
	return (
		<>
			<Grid container className="signup-container" id="landing-signup-container">
				<div id="canvas-container-sign-up">
					<div className="canvas-wrapper">
						<canvas id="gradient-canvas-sign-up"></canvas>
					</div>
				</div>
				<Grid item xs={1}></Grid>
				<Grid item xs={10} display={"flex"} justifyContent={"center"} alignItems={"center"} textAlign={"center"} sx={{ marginTop: "4em" }}>
					<Typography variant="h4">{t("signup.landing.title")}</Typography>
				</Grid>
				<Grid item xs={1}></Grid>
				<Grid item xs={12}>
					<SignupForm />
				</Grid>
			</Grid>
		</>
	);
};
