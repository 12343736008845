import { Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { TeamMemberType } from "../../../../types/Home/AboutUs/TeamMemberPopup";
import { useContext, useEffect, useRef } from "react";
import { FirebaseContext } from "../../../Providers/FirebaseProvider";

export const TeamPopup = ({
	teamInfo,
	handleClosePopup,
	isPopupOpen,
}: {
	teamInfo: TeamMemberType;
	handleClosePopup: () => void;
	isPopupOpen: boolean;
}) => {
	const { myStorageUrl } = useContext(FirebaseContext);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const pageContainer = document.getElementById("page-container");
		if (pageContainer == null) return;
		function handleClickOutside(event) {
			if (
				containerRef.current === null ||
				event.target.classList.contains("team-member-image") ||
				event.target.classList.contains("team-circle")
			)
				return;
			const divElement = containerRef.current;
			if (!divElement.contains(event.target)) {
				handleClosePopup();
				pageContainer.classList.remove("blur");
			}
		}
		if (isPopupOpen && window.innerWidth < 700) {
			pageContainer.classList.add("blur");
		} else {
			pageContainer.classList.remove("blur");
		}

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [isPopupOpen]);
	return (
		<>
			<Dialog
				open={isPopupOpen}
				onClose={handleClosePopup}
				fullWidth
				PaperProps={{ sx: { width: "100%", maxWidth: { xs: "100%" } } }}
				className="pop-up-team-member-dialog-container"
				scroll={window.innerWidth < 700 ? "body" : "paper"}
			>
				<DialogContent>
					<Grid
						container
						columns={16}
						alignItems="center"
						justifyContent="center"
						className="pop-up-team-member-container"
						ref={containerRef}
						rowGap={3}
					>
						<Grid item xs={16} md={4} xl={4} className="team-member-image-container">
							<img src={`${myStorageUrl}${teamInfo.image}`} alt="team-member" className="team-member-image" />
						</Grid>
						<Grid item xs={16} md={8} className="team-member-info-container" alignSelf="stretch">
							<Grid container rowGap={4}>
								<Grid item xs={12} className="team-member-name-container">
									<Typography variant="h4" className="team-member-name">
										{teamInfo.name}
									</Typography>
									<Typography variant="h4" className="team-member-role">
										{teamInfo.role}
									</Typography>
								</Grid>
								<Grid item xs={12} className="team-member-description-container">
									<Typography variant="h4" className="team-member-description">
										{teamInfo.description}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={16} md={4} xl={4} className="team-member-links-container" alignSelf="stretch">
							<Grid container rowGap={1} gap={1}>
								<Grid item xs={12}>
									<Typography variant="h3" className="team-member-favourite-game-title">
										Gioco preferito:
									</Typography>
								</Grid>
								<Grid item xs={12} className="team-member-favourite-game">
									{teamInfo.favouriteGame === "Fortnite" ? (
										<img src={`${myStorageUrl}images/fortnite-logo.png`} alt={`Fortnite Logo`} />
									) : (
										<img src={`${myStorageUrl}images/dota2logo.png`} alt={`Dota Logo`} />
									)}
									<Typography variant="h3" className="team-member-favourite-game-text">
										{teamInfo.favouriteGame}
									</Typography>
								</Grid>
								{teamInfo.link.map((link, index) => (
									<Grid item xs={4} sm={12} className="team-member-links-item" key={index}>
										<a href={link.url} target="_blank" className="team-member-links">
											{link.name}
										</a>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};
