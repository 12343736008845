import { Link, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../Providers/AuthProvider";
import { OutlinedButtonV1 } from "../../Buttons/CustomOutlinedButtonV1";
import { Box, Stack } from "@mui/system";
import GradientTypography from "../../Typographies/GradientTypography";
import { SettingsContext } from "../../Providers/SettingsProvider";
import { OutlinedButtonV2 } from "../../Buttons/CustomOutlinedButtonV2";

export const PremiumInfo = () => {
	const { profile } = useContext(AuthContext);
	const { t: tProfilePage } = useTranslation("profilepage");
	const { t: tDashboard } = useTranslation("dashboard");
	const { changeActiveDashboard } = useContext(SettingsContext);

	const dateExpire = profile !== undefined && profile.isPremium ? new Date(profile?.premiumEndDate * 1000) : null;

	const openMarket = () => {
		changeActiveDashboard("market");
	};

	const openDashboard = () => {
		changeActiveDashboard("home");
	};

	return (
		<>
			<Box
				sx={{
					height: "100%",
				}}>
				<Stack
					spacing={2}
					sx={{
						padding: "1em",
						height: "100%",
					}}>
					<Box sx={{ fontSize: "20px" }}>
						{tProfilePage("premium.info.box.title")}
						<GradientTypography
							content={profile.isPremium ? tProfilePage("premium.user") : tProfilePage("freemium.user")}
							variant="caption"
							gradientVersion={"v2"} // Use 'v1' or 'v2' based on your preference
							sx={{ fontSize: "20px" }}
						/>
					</Box>
					<Typography variant="caption" sx={{ opacity: "70%" }}>
						{profile != null && profile != null && profile.isPremium ? (
							<>
								<Link onClick={openDashboard} sx={{ color: "white", textDecorationColor: "white", cursor: "pointer" }}>
									{tProfilePage("go.to.dashboard")}
								</Link>
								{tProfilePage("to.visualize.benefits")}
								<Box sx={{ marginTop: "1em" }}>
									{tProfilePage("your.subscription.expires.on")}
									<span style={{ textDecoration: "underline" }}>
										{dateExpire?.toLocaleDateString(navigator.language || (navigator.languages || ["en"])[0])}
									</span>
								</Box>
							</>
						) : (
							<>
								<Box>
									{tProfilePage("freemium.info.box.content#1")}
									<GradientTypography gradientVersion={"v2"} content={tProfilePage("premium.user")} variant="caption" />
									{tProfilePage("freemium.info.box.content#2")}
								</Box>
								<Box sx={{ marginTop: "1em" }}>
									<ul>
										<li>{tDashboard("market.subscription.card.guaranteed.daily.gem.distribution")}</li>
										<li>{tDashboard("market.subscription.card.daily.monthly.quests")}</li>
										<li>{tDashboard("market.subscription.card.charts.analytics")}</li>
										{/* <li>{tDashboard("market.subscription.card.extra.lives.showdown")}</li>
										<li>{tDashboard("market.subscription.card.no.ads")}</li> */}
									</ul>
									<span>
										<span className="fw-bold">{tDashboard("market.subscription.card.duration.text#1")}</span>:{" "}
										{tDashboard("market.subscription.card.duration.text#2")}
									</span>
								</Box>
							</>
						)}
					</Typography>
					{profile != null && profile != null && profile.isPremium ? (
						<OutlinedButtonV2 sx={{ fontSize: "10px", width: "100%" }} onClick={openMarket} content={tProfilePage("premium.info.button")} />
					) : (
						<OutlinedButtonV1 sx={{ fontSize: "10px", width: "100%" }} onClick={openMarket} content={tProfilePage("freemium.info.button")} />
					)}
				</Stack>
			</Box>
		</>
	);
};
