import { Grid, ThemeProvider, createTheme } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../Providers/AuthProvider";

import { QuestProgressionCard } from "./QuestProgressionCard";

/**
 * @dev This contains both monthly and daily premium quests cards
 * @param {*} props
 */

declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		tablemedium: true;
		md: true;
		lgsmall: true;
		lg: true;
		largemedium: true;
		xl: true;
		xxl: true;
		xxxl: true;
	}
}

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			tablemedium: 800,
			md: 900,
			lgsmall: 1000,
			lg: 1200,
			largemedium: 1350,
			xl: 1536,
			xxl: 1900,
			xxxl: 2500,
		},
	},
});

export const QuestProgressionContainer = (): JSX.Element => {
	const [distributionDateDifference, setDistributionDateDifference] = useState(0);
	const { profile } = useContext(AuthContext);

	useEffect(() => {
		timerDristribution();
	}, []);
	function timerDristribution(): void {
		const date = new Date();

		if (date.getUTCHours() == 24 && date.getUTCMinutes() > 30) {
			date.setUTCDate(date.getUTCDate() + 1);
		}
		date.setUTCHours(24, 30, 0, 0);
		const UTCTimeNow = new Date();

		const now_utc = Date.UTC(UTCTimeNow.getUTCFullYear(), UTCTimeNow.getUTCMonth(), UTCTimeNow.getUTCDate(), UTCTimeNow.getUTCHours(), UTCTimeNow.getUTCMinutes(), UTCTimeNow.getUTCSeconds());
		const diffeerence = date.getTime() - now_utc;
		setDistributionDateDifference(diffeerence);
	}

	return (
		<>
			<ThemeProvider theme={theme}>
				<Grid textAlign={"center"} container alignItems={"center"} justifyContent={"center"} direction="row" spacing={2}>
					<Grid item {...{ xs: 12, sm: 12, md: 12, lgsmall: 6 }} alignSelf={"stretch"}>
						<QuestProgressionCard title={"Daily quests"} type="daily" timeDifference={distributionDateDifference} />
					</Grid>
					<Grid item {...{ xs: 12, sm: 12, md: 12, lgsmall: 6 }} alignSelf={"stretch"}>
						<QuestProgressionCard title={"Monthly quests"} type="monthly" timeDifference={distributionDateDifference} />
					</Grid>
				</Grid>
			</ThemeProvider>
		</>
	);
};
