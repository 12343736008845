import { Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { isPassword, isEmail } from "../../../AuthComponents/Validation";
import { Button, Grid, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Providers/AuthProvider";
import { useSnackbar } from "../../../Providers/SnackbarProvider";
import { useTranslation } from "react-i18next";
import { CheckPolicies } from "../../../AuthComponents/CheckPolicies";
import { GoogleSignInButton } from "../../../AuthComponents/GoogleSignInButton";
import { FirebaseContext } from "../../../Providers/FirebaseProvider";
import { getTermsAndConditions } from "../../../../utilities/policies/getPrivacyPoliciesLinks";

const SignupSchema = Yup.object().shape({
	username: Yup.string().min(6, "Too short").max(35, "Too long").required("Required"),
	email: Yup.string()
		.email("Invalid email")
		.required("Required")
		.test("email-validation", "Invalid email", (value) => isEmail(value)),
	password: Yup.string()
		.min(8, "Too short")
		.max(35, "Too long")
		.required("Required")
		.test("password-validation", "Invalid password", (value) => isPassword(value)),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Passwords must match")
		.required("Required"),
	privacyPolicies: Yup.boolean().oneOf([true], "Required"),
});

export const SignupForm = () => {
	const { checkIfUsernameIsTaken, register, validateCode, loading } = useContext(AuthContext);
	const { openSnackbar } = useSnackbar();
	const [userAcceptedTerms, setUserAcceptedTerms] = useState<boolean>(false);
	const [userAcceptedClauses, setUserAcceptedClauses] = useState<boolean>(false);
	const { t } = useTranslation(["landing"]);
	const { t: t2 } = useTranslation(["dashboard"]);
	const { storagePicturesUrl } = useContext(FirebaseContext);

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

	const handleSubmit = async (values: FormikValues) => {
		const { username, email, password, confirmPassword, promoCode, privacyPolicy } = values;

		try {
			if (!privacyPolicy) {
				openSnackbar("Error", t("you must accept our privacy policy"), "error");
				return;
			}
			if (!userAcceptedTerms) {
				openSnackbar("Error", t2("accept.terms.auth.warning"), "error");
				return;
			}
			if (!userAcceptedClauses) {
				openSnackbar("Error", t2("accept.clauses.auth.warning"), "error");
				return;
			}
			if (await checkIfUsernameIsTaken(username)) {
				openSnackbar("Error", t("username already used"), "error");
				return;
			}
			if (promoCode !== "") {
				const { isCodeValid, message } = await validateCode(promoCode);
				if (!isCodeValid) {
					openSnackbar("Error", message, "error");
					return;
				}
			}
			const userRegistered = await register({
				username,
				email,
				password,
				promoCode,
				confirmEmail: email,
				confirmPassword,
			});

			if (userRegistered) {
				openSnackbar("About cookies", t("cookieAlertSignupPage"), "info");
				window.location.href = "/dashboard";
			} else {
				openSnackbar("Error", t("something went wrong"), "error");
			}
		} catch (error) {
			console.log(error);
			openSnackbar("Error", t(error.message), "error");
		}
	};

	return (
		<Formik
			validationSchema={SignupSchema}
			validateOnChange={true}
			initialValues={{ email: "", password: "", confirmPassword: "", username: "", promoCode: "", privacyPolicy: false }}
			onSubmit={handleSubmit}
		>
			{(formik) => (
				<Form onSubmit={formik.handleSubmit}>
					<Grid container>
						<Grid item xs={3}></Grid>
						<Grid item xs={6} className="form-container">
							<Grid container rowSpacing={2} sx={{ marginTop: "4em", marginBottom: "2em" }}>
								<Grid item xs={12}>
									<TextField
										id="landing-form-inputfield-email"
										name="email"
										value={formik.values.email}
										label="Email"
										onChange={formik.handleChange}
										variant="outlined"
										className="form-input-field"
										error={formik.touched.email && Boolean(formik.errors.email)}
										helperText={formik.touched.email && formik.errors.email}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="landing-form-inputfield-username"
										name="username"
										value={formik.values.username}
										label="Username"
										onChange={formik.handleChange}
										className="form-input-field"
										error={formik.touched.username && Boolean(formik.errors.username)}
										helperText={formik.touched.username && formik.errors.username}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="landing-form-inputfield-password"
										name="password"
										value={formik.values.password}
										label="Password"
										onChange={formik.handleChange}
										className="form-input-field"
										type={showPassword ? "text" : "password"}
										error={formik.touched.password && Boolean(formik.errors.password)}
										helperText={formik.touched.password && formik.errors.password}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() => setShowPassword(!showPassword)}
														edge="end"
														aria-label="toggle password visibility"
													>
														{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="landing-form-inputfield-confirm-password"
										name="confirmPassword"
										value={formik.values.confirmPassword}
										label={t("confirm.password")}
										onChange={formik.handleChange}
										className="form-input-field"
										type={showConfirmPassword ? "text" : "password"}
										error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
										helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() => setShowConfirmPassword(!showConfirmPassword)}
														edge="end"
														aria-label="toggle password visibility"
													>
														{showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="landing-form-inputfield-confirm-referral-code"
										name="promoCode"
										value={formik.values.promoCode}
										label={t("referral.code")}
										onChange={formik.handleChange}
										className="form-input-field"
										error={formik.touched.promoCode && Boolean(formik.errors.promoCode)}
										helperText={formik.touched.promoCode && formik.errors.promoCode}
									/>
								</Grid>
								<Grid item xs={12}>
									<CheckPolicies
										onChange={(e) => {
											formik.setFieldValue("privacyPolicy", e.target.checked);
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<label id={"confirm-privacy-policies-text"}>
										{t2("i accept the")}
										<a className={"privacy-policies-link"} href={`${storagePicturesUrl}privacy_policies/${getTermsAndConditions()}`} target="_blank" rel="noreferrer" title="Terms and conditions">
											{t2("terms.and.conditions")}
										</a>
									</label>
									<label className="checkbox" style={{ opacity: 1, background: 0 }}>
										<input type="checkbox" id={"confirm-privacy-policies-checkbox"} name={"confirm-privacy-policies-checkbox"} onChange={() => { setUserAcceptedTerms(!userAcceptedTerms) }} />
										<span></span>
									</label>
								</Grid>
								<Grid item xs={12}>
									<Tooltip title={t2('accept.clauses.content')}>
										<label id={"confirm-privacy-policies-text"}>
											<a className={"privacy-policies-link"} target="_blank" rel="noreferrer" title="Privacy Policy">
												{t2("accept.clauses.auth")}
											</a>
										</label>
									</Tooltip>
									<label className="checkbox" style={{ opacity: 1, background: 0 }}>
										<input type="checkbox" id={"confirm-privacy-policies-checkbox"} name={"confirm-privacy-policies-checkbox"} onClick={() => {
											setUserAcceptedClauses(!userAcceptedClauses);
										}} />
										<span></span>
									</label>
								</Grid>
								<Grid item xs={12} sx={{ marginTop: "1em" }}>
									<Button disabled={loading} type="submit" variant="contained">
										{t("button.join.us")}
									</Button>
									<GoogleSignInButton source="signup" privacyAccepted={formik.values.privacyPolicy} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={3}></Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};
