import "react-tabs/style/react-tabs.css";
import { FormEvent, useContext, useRef } from "react";
import "../../../styles/Settings.scss";
import { Grid, Stack } from "@mui/material";
import { AuthContext } from "../../Providers/AuthProvider";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { sendPasswordResetEmail } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { ProfilePagePRops } from "../../../types/Props";
import { FooterLanding } from "../../home/landing/FooterLanding";
import { ProfileImageBox } from "./ProfileImageBox";
import { ProfileChangeInfo } from "./ProfileChangeInfo";
import { PremiumInfo } from "./PremiumInfo";
import { GamesConnectedBox } from "./GamesConnectedBox";
import { FavouriteGameBox } from "./FavouriteGameBox";
import { ContactUsBox } from "./ContectUsBox";
import { LogoutButtonBox } from "./LogoutButtonBox";
import { DeleteAccountBox } from "./DeleteAccountBox";

export const DashboardProfile = (props: ProfilePagePRops): JSX.Element => {
	const { profile, sendVerification } = useContext(AuthContext);
	const { t } = useTranslation("dashboard");
	const { t: tProfilePage } = useTranslation("profilepage");

	const handleVerifyMail = async (): Promise<void> => {
		if (profile.emailVerified) {
			props.showToast(t("account already verified"), "Info");
			return;
		}
		props.showToast(`${t("email sent to")}: ${profile.email}`, "Success");
		await sendVerification();
	};

	return (
		<div className="main">
			<div className="col-md-12" id={"settings-title-container"} style={{ margin: "50px 0;" }}>
				<h1 className="profile-title text-center">{tProfilePage("profile.page.title")}</h1>
			</div>
			<Grid container justifyContent="center" alignItems="center" gap={{ sm: 1, md: 2 }} rowGap={3}>
				<Grid
					item
					xs={11}
					sm={5}
					md={2}
					sx={{
						background: "black",
						borderRadius: "20px",
						height: "260px",
					}}>
					<ProfileImageBox />
				</Grid>
				<Grid
					item
					xs={11}
					sm={5}
					md={5}
					sx={{
						background: "black",
						borderRadius: "20px",
						height: "260px",
					}}>
					<ProfileChangeInfo />
				</Grid>
				<Grid
					item
					xs={11}
					sm={5}
					md={3}
					sx={{
						background: `
						linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.66) 53.33%, #000000 100%),
    					radial-gradient(68.5% 68.5% at 77.17% -0.75%, #2E4C79 0%, rgba(66, 136, 189, 0) 100%),
    					linear-gradient(0deg, #5F398E, #5F398E),
    					radial-gradient(66.66% 85.62% at 86.99% 88.87%, #B275BB 0%, rgba(173, 67, 188, 0.84) 49.17%, rgba(164, 75, 177, 0.82) 60.63%, rgba(136, 62, 182, 0) 92.4%);
					`,
						borderRadius: "20px",
						height: "260px",
					}}>
					<PremiumInfo />
				</Grid>
				<Grid
					item
					sx={{
						background: "black",
						borderRadius: "20px",
						height: "170px",

						"@media screen and (min-width: 600px) and (max-width: 900px)": {
							height: "260px",
						},
					}}
					xs={11}
					sm={5}
					md={5}>
					<GamesConnectedBox />
				</Grid>
				<Grid
					item
					sx={{
						background: "black",
						borderRadius: "20px",
						height: "170px",
					}}
					xs={11}
					sm={5}
					md={2}>
					<FavouriteGameBox />
				</Grid>
				<Grid item xs={11} sm={5} md={3}>
					<Stack spacing={3}>
						<ContactUsBox />
						<LogoutButtonBox />
					</Stack>
				</Grid>
				<Grid item xs={11} sm={5} md={5}>
					<DeleteAccountBox />
				</Grid>
			</Grid>
			<div style={{ position: "relative", marginBottom: "2em" }}>
				<FooterLanding />
			</div>
		</div>
	);
};
