import { Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import "../../../styles/home/aboutUs/Team.scss";

import "../../../styles/home/aboutUs/TeamPopup.scss";
import { useTranslation } from "react-i18next";
import { TeamMemberType } from "../../../types/Home/AboutUs/TeamMemberPopup";
import { TeamPopup } from "./Team/TeamPopup";
import { GetTeamInformation } from "../../../Team/teamInformation";
import { FirebaseContext } from "../../Providers/FirebaseProvider";

export const Team = () => {
	const { t } = useTranslation("landing");
	const [isPopupOpen, setisPopupOpen] = useState<boolean>(false);
	const [teamMember, setTeamMember] = useState<TeamMemberType | null>(null);
	const { myStorageUrl } = useContext(FirebaseContext);

	const handlePopupOpen = (member: TeamMemberType) => {
		setTeamMember(member);
		setisPopupOpen(true);
	};
	const handleClosePopup = () => {
		setisPopupOpen(false);
	};
	const teamInformation = GetTeamInformation();

	return (
		<>
			{teamMember !== null && <TeamPopup teamInfo={teamMember} isPopupOpen={isPopupOpen} handleClosePopup={handleClosePopup}></TeamPopup>}

			<Grid container justifyContent="center" alignItems="center">
				<Grid item xs={11} sm={10} display="flex">
					<Grid container>
						<Grid item xs={12} md={6} className="team-container">
							<div className="team-circle-section">
								{teamInformation.map((value, index) => (
									<div className="team-circle " onClick={() => handlePopupOpen(value)} key={"team-member-" + index}>
										<img src={`${myStorageUrl}${value.image}`} alt="Ruben Soro Founder"></img>
									</div>
								))}
							</div>
							<div id="first-bubble-team-background"></div>
							<div id="second-bubble-team-background"></div>
							<div id="third-bubble-team-background"></div>
							<div id="fourth-bubble-team-background"></div>
						</Grid>
						<Grid item xs={12} md={6} className="team-text-section">
							<Typography variant="h3" className="story-telling-text" textAlign="center">
								{t("aboutus.team")}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
