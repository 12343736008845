import { Grid, Typography } from "@mui/material";
import { useContext, useEffect } from "react";

import { HeroAnimation } from "../../../Javascript/HeroAnimation";

import hero_cut from "../../../assets/images/landing_hero_cut.svg";

import "../../../styles/home/Vision.scss";
import { SettingsContext } from "../../Providers/SettingsProvider";
import { WalletContext } from "../../Providers/WalletProvider";

export const Vision = () => {
	const { language } = useContext(SettingsContext);
	useEffect(() => {
		HeroAnimation("#gradient-canvas-hero");
		const element = document.getElementById("canvas-container");
		element.classList.add("hero-canvas-animaiton");
	}, []);

	const handleClick = () => {
		document.getElementById("landing-signup-container").scrollIntoView({ behavior: "smooth" });
	};

	return (
		<>
			{true && (
				<Grid container justifyContent="center" alignItems="center" id="vision-container">
					<div id="canvas-container">
						<div className="canvas-wrapper">
							<canvas id="gradient-canvas-hero"></canvas>
						</div>
					</div>
					<img src={hero_cut} alt="hero cut" className="hero-cut" />
					<Grid
						item
						xs={10}
						mb={6}
						xl={4}
						sx={{ marginTop: { xs: "25px", sm: "75px", mb: "100px" }, textAlign: "left", padding: "20px", zIndex: 123 }}>
						{/*
						 * this code does not use i18n because it takes some time to load the translations from the JSON file and since
						 * this is the first copy that the user sees it would be a poor UX
						 */}
						<Typography variant="h2" component="h2" className="vision-title">
							{language === "en" ? (
								<>A digital arena where players can turn their eSports dreams into reality</>
							) : (
								<>Un'arena digitale dove i giocatori possono trasformare i loro sogni di eSports in realtà</>
							)}
						</Typography>
						<button className="button-landing hero-button" style={{ marginTop: "16px" }} onClick={handleClick}>
							{language === "en" ? <>Join us</> : <>Unisciti a eStorm</>}
						</button>
					</Grid>
				</Grid>
			)}
		</>
	);
};
