import { Grid } from "@mui/material";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import $ from "jquery";
import { useEffect } from "react";
import { QuestContentProps } from "../../../../types/Props";
import { AuthContext } from "../../../Providers/AuthProvider";
import { SettingsContext } from "../../../Providers/SettingsProvider";

export const QuestContent = (props: QuestContentProps): JSX.Element => {
	const { t } = useTranslation("dashboard");

	const [idProgrssion, setIdProgression] = useState("");
	const [idBackground, setIdBackground] = useState("");
	const [columnsQuests, setColumnsQuests] = useState(7);
	const { profile } = useContext(AuthContext);
	const { language } = useContext(SettingsContext);

	useEffect(() => {
		setIdProgression(props.id);
		setIdBackground(props.idBackground);
		if (props.type === "daily") {
			setColumnsQuests(7);
		} else {
			setColumnsQuests(10);
		}
		if (profile != null && profile.isPremium) {
			if (idProgrssion != "") {
				$(`#${idProgrssion}`).css("background", `rgba(217, 217, 217, 0.3)`).css("width", `${props.progression}%`);
			}
			if (idBackground != "") {
				if (props.progression == 100) {
					$(`#${idBackground}`).css("background", `linear-gradient(90deg, #AB00C7 0.59%, #0075FF 97.84%)`);
					$(`#${idProgrssion}`).css("background", `transparent`).css("width", `0%`);
				}
			}
		}
	}, [idProgrssion, idBackground, props]);

	return (
		<>
			<Grid item xs={12} sm={columnsQuests} className="background-quest-content" id={idBackground}>
				<div className="background-progression-quest" id={idProgrssion}></div>
				<Grid container>
					<Grid item xs={12}>
						{profile != null && profile.isPremium ? (
							<>
								<h5>
									{props.type === "daily" ? (
										<>{props.descriptions[language]}</>
									) : (
										<>{props.descriptions[language]}</>
									)}
								</h5>
								<label>
									{t("quest level")} : {props.level}{props.isPremiumQuests && <> - {props.progression}%</>}
								</label>
								<br/>
								{props.dailyPoints && <label>Daily points: {props.dailyPoints}</label>}
							</>
						) : (
							<>
								<h5>Title</h5>
								<label>progression</label>
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
