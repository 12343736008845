import { Grid, Typography } from "@mui/material"
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../Providers/AuthProvider";
import { SettingsContext } from "../../Providers/SettingsProvider";
import { TFunction } from "i18next";
import GradientTypographyBoxTitle from "../../Typographies/GradientTypographyBoxTitle";
import { useSnackbar } from "../../Providers/SnackbarProvider";
import { ChangeUsernameAPIResponse } from "../../../types/APIResponse";
import { NetworkContext } from "../../Providers/NetworkProvider";
import { changeUsernameAPI } from "../../../utilities/network/api";
import { OutlinedButtonV1 } from "../../Buttons/CustomOutlinedButtonV1";
import { sendPasswordResetEmail } from "firebase/auth";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import config from "../../../config-file.json";

export const ProfileChangeInfo = () => {
    const { t } = useTranslation("profilepage");

    return (
        <Grid container className="profile-upload" >
            <Grid item xs={12} sx={{ padding: '1em' }}>
                <GradientTypographyBoxTitle content={t('profile.information')} variant="caption" />
            </Grid>
            <Grid item xs={12}>
                <ProfileInfoContentUsername t={t} />
            </Grid>
            <Grid item xs={12}>
                <ProfileInfoContentLanguage t={t} />
            </Grid>
            <Grid item xs={12}>
                <ProfileInfoChangePassword t={t} />
            </Grid>
        </Grid>
    )
}

const ProfileInfoContentUsername = ({ t }: {
    t: TFunction
}) => {
    const { profile } = useContext(AuthContext);
    const { openSnackbar } = useSnackbar();
    const { makePost } = useContext(NetworkContext);
    const newUsernameInputFieldRef = useRef(null);
    const { t: tResponses } = useTranslation('responses');

    const changeUsernameWrapper = async (): Promise<void> => {
        try {
            await changeUsername();
        } catch (error) {
            openSnackbar(t('error'), error.message, "error");
        }
    };

    const changeUsername = async (): Promise<void> => {
        checkInput();
        const newUsername = newUsernameInputFieldRef.current.value;
        const res = await makePost<ChangeUsernameAPIResponse>(changeUsernameAPI, {
            _token: `Bearer ${profile.idToken}`,
            newUsername: newUsername,
        });
        if (res.status === 200) {
            if (res.data.i18nKey != null)
                openSnackbar(t('success'), tResponses(res.data.i18nKey), "success");
            else
                openSnackbar(t('success'), res.data.message, "success");
        } else {
            if (res.data.i18nKey != null)
                openSnackbar(t('error'), tResponses(res.data.i18nKey), "error");
            else
                openSnackbar(t('error'), res.data.message, "error");
        }
    }

    const checkInput = () => {
        if (newUsernameInputFieldRef.current.value.length < 5) {
            throw new Error(t("username.too.short"));
        } else if (newUsernameInputFieldRef.current.value.length > 16) {
            throw new Error(t("username.too.long"));
        } else if (newUsernameInputFieldRef.current.value === profile.username) {
            throw new Error(t("new.name.is.equal.to.name"));
        }
    }

    return (
        <Grid container style={{ paddingLeft: '1em', paddingTop: '1em' }}>
            <Grid item xs={3} style={{ textAlign: 'center', opacity: '50%' }}>
                <Typography variant="caption" >Username</Typography>
            </Grid>
            <Grid item xs={6} sm={7}>
                <input ref={newUsernameInputFieldRef} placeholder={profile.username} />
                <hr />
            </Grid>
            <Grid item xs={2}>
                <Typography onClick={changeUsernameWrapper}
                    variant="caption"
                    className="gradient-text-conatainer"
                    sx={{ cursor: "pointer" }}
                >
                    <span className="gradient-text">{t('modify')}</span>
                </Typography>
            </Grid>

        </Grid>
    )
}

const ProfileInfoContentLanguage = ({ t }: {
    t: TFunction
}) => {
    const { changeLanguage, language } = useContext(SettingsContext);

    return (
        <Grid container style={{ paddingLeft: '1em', paddingTop: '0.3em' }}>
            <Grid item xs={3} sm={3} style={{ textAlign: 'center', opacity: '50%' }}>
                <Typography variant="caption">{t('language')}</Typography>
            </Grid>
            <Grid item xs={7} sm={8}>
                <select value={language} name="languages" id="languages-form" onChange={(e) => changeLanguage(e.target.value)}>
                    <option value="en">English</option>
                    <option value="it">Italiano</option>
                </select>
                <hr />
            </Grid>
        </Grid>
    )
}

const ProfileInfoChangePassword = ({ t }: {
    t: TFunction
}) => {
    const { myAuth } = useContext(FirebaseContext);
    const { profile } = useContext(AuthContext);
    const { openSnackbar } = useSnackbar();
    const { t: tGeneral } = useTranslation('translation');

    const sendMailForPasswordReset = async (): Promise<void> => {
        try {
            await sendPasswordResetEmail(myAuth, profile.email, { url: config[process.env.REACT_APP_ENV]["url-used-to-send-mail-auth-login"] });
            openSnackbar(tGeneral('email.sent'), tGeneral('check.email'), 'warning');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Grid container style={{ paddingLeft: '1em', paddingTop: '0.3em' }}>
            <Grid item xs={3} sm={3} style={{ textAlign: 'center', opacity: '50%' }}>
                <Typography variant="caption">{t('password')}</Typography>
            </Grid>
            <Grid item xs={7} sm={8} sx={{ textAlign: 'left' }}>
                <OutlinedButtonV1 sx={{ transform: 'scale(0.7)', position: 'relative', left: '-1.5em' }} content={t('change.password')} onClick={sendMailForPasswordReset} />
            </Grid>
        </Grid>
    )
}