import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { OutlinedButtonV2 } from "../../Buttons/CustomOutlinedButtonV2";
import { Trans, useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRef, useState, RefObject, useContext } from "react";
import { Form, Formik } from "formik";
import { NetworkContext } from "../../Providers/NetworkProvider";
import { AuthContext } from "../../Providers/AuthProvider";
import { useSnackbar } from "../../Providers/SnackbarProvider";
import { useNavigate } from "react-router-dom";

export const DeleteAccountBox = () => {
	const { t } = useTranslation("profilepage");
	const { t: tResponses } = useTranslation("responses");
	const [openDialog, setOpenDialog] = useState(false);
	const { makePost } = useContext(NetworkContext);
	const { profile } = useContext(AuthContext);
	const { openSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleOpenDialog = async () => {
		try {
			setIsLoading(true);
			// send request to generate code
			const res = await makePost<{
				ok: boolean;
				i18nKey: string;
			}>("/account/delete/generateCode", {
				_token: `Bearer ${profile.idToken}`,
			});
			setIsLoading(false);

			if (res.status === 200) {
				openSnackbar(t("success"), tResponses(res.data.i18nKey), "success");
			} else {
				openSnackbar(t("error"), tResponses(res.data.i18nKey), "error");
			}

			// open dialog
			setOpenDialog(true);
		} catch (error) {
			setIsLoading(false);
			openSnackbar(t("error"), error.message, "error");
		}
	};

	return (
		<>
			<ConfirmDeleteAccount isOpen={openDialog} handleClose={handleCloseDialog} />
			<Box
				sx={{
					background: "black",
					borderRadius: "20px",
				}}
				textAlign={"center"}>
				<OutlinedButtonV2
					onClick={handleOpenDialog}
					sx={{
						fontSize: "10px",
						width: "60%",
						margin: "1.4em",
						color: "red",
						borderColor: "red",
						"&:hover": { borderColor: "red", backgroundColor: "red", color: "white" },
					}}>
					{isLoading ? (
						<>
							{t("generating.code").toUpperCase()}{" "}
							<CircularProgress
								size={20}
								sx={{
									color: "white",
									marginLeft: "1rem",
								}}
							/>
						</>
					) : (
						<>
							{t("delete.account").toUpperCase()} <DeleteIcon />
						</>
					)}
				</OutlinedButtonV2>
			</Box>
		</>
	);
};

const ConfirmDeleteAccount = ({ isOpen, handleClose }: { isOpen: boolean; handleClose: () => void }) => {
	const { t } = useTranslation("profilepage");
	const { t: tResponses } = useTranslation("responses");
	const btnConfirmRef = useRef<HTMLButtonElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const { makePost } = useContext(NetworkContext);
	const { profile } = useContext(AuthContext);
	const { openSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const handleConfirm = async () => {
		const { value } = inputRef.current;

		if (value === "") {
			return;
		}

		// send request to delete account
		console.log(value);
		try {
			makePost<{
				ok: boolean;
				i18nKey: string;
			}>("/account/delete/confirm", {
				_token: `Bearer ${profile.idToken}`,
				code: value,
			});
			navigate("/");
		} catch (error) {
			openSnackbar(t("error"), error.message, "error");
		}

		// close dialog
		handleClose();
	};

	return (
		<Box component={Dialog} open={isOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title" textTransform="capitalize" fontSize={25} fontWeight="bold" sx={{ paddingTop: 0 }}>
				{t("confirm.delete.account.dialog.title")}
			</DialogTitle>
			<DialogContent
				style={{
					flexGrow: 0,
				}}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="body1" sx={{ textAlign: "center", fontSize: "16px", whiteSpace: "break-spaces" }}>
							<Trans
								t={t}
								i18nKey="confirm.delete.account.dialog.content"
								components={{
									warning: <Box component="span" sx={{ fontWeight: "bold", color: "red" }}></Box>,
								}}
							/>
						</Typography>
					</Grid>
					<Grid item xs={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={2}>
						<Typography variant="body1" sx={{ textAlign: "center", fontSize: "14px", whiteSpace: "break-spaces" }}>
							<Trans
								t={t}
								i18nKey="confirm.delete.account.dialog.guide"
								components={{
									warning: <Box component="span" sx={{ fontWeight: "bold", color: "red" }}></Box>,
								}}
							/>
						</Typography>
						<PhraseValidation btnConfirmRef={btnConfirmRef} inputRef={inputRef} />
					</Grid>
				</Grid>

				<DialogActions sx={{ justifyContent: "center", gap: 3 }}>
					<OutlinedButtonV2
						ref={btnConfirmRef}
						onClick={handleConfirm}
						sx={{
							width: "30%",
							borderColor: "red",
							backgroundColor: "red",
							"&:hover": {
								backgroundColor: "transparent",
								color: "red",
								borderColor: "red",
							},
							"&:disabled": {
								cursor: "not-allowed",
								backgroundColor: "transparent",
								color: "gray",
								borderColor: "gray",
							},
						}}
						content={t("confirm.delete.account.dialog.confirm")}
					/>
					<OutlinedButtonV2
						onClick={handleClose}
						sx={{
							width: "30%",
							borderColor: "transparent",
							textDecoration: "underline",
							"&:hover": {
								backgroundColor: "white",
								borderColor: "white",
								color: "black",
							},
						}}
						content={t("confirm.delete.account.dialog.cancel")}
					/>
				</DialogActions>
			</DialogContent>
		</Box>
	);
};

const PhraseValidation = ({ btnConfirmRef, inputRef }: { btnConfirmRef: RefObject<HTMLButtonElement>; inputRef: RefObject<HTMLInputElement> }) => {
	const { t } = useTranslation("profilepage");

	return (
		<Formik
			initialValues={{ confirmDeleteAccount: "" }}
			validateOnMount={true}
			validateOnChange={true}
			validate={(values) => {
				if (values.confirmDeleteAccount === "") {
					btnConfirmRef.current!.disabled = true;
				} else {
					btnConfirmRef.current!.disabled = false;
				}
			}}
			onSubmit={() => {}}>
			{(formik) => (
				<Form>
					<TextField
						inputRef={inputRef}
						id="profile-page-form-inputfield-confirm-delete-account"
						name="confirmDeleteAccount"
						value={formik.values.confirmDeleteAccount}
						label={t("confirm.delete.account.dialog.placeholder")}
						onChange={formik.handleChange}
						variant="outlined"
						className="form-input-field"
						error={formik.touched.confirmDeleteAccount && Boolean(formik.errors.confirmDeleteAccount)}
						helperText={formik.touched.confirmDeleteAccount && formik.errors.confirmDeleteAccount}
					/>
				</Form>
			)}
		</Formik>
	);
};
