import { Box } from "@mui/material";
import { useState } from "react";
import DropdownList from "react-widgets/esm/DropdownList";

export const CustomSelect = ({ onChange, values }: {
    onChange: (value: string) => void;
    values: string[];
}) => {
    const [value, setValue] = useState<string>("");

    return (
        <>
            <div className="country-selection">
                <div className="dropdown-container">
                    <DropdownList
                        style={{
                            height: '30px'
                        }}
                        value={value}
                        placeholder="Select a country"
                        onChange={(newValue) => {
                            setValue(newValue as string);
                            onChange(newValue as string);
                        }}
                        data={values}
                    />
                </div>
            </div>
        </>
    );
}