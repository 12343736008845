import { useEffect, useRef } from "react";

import "../../styles/LoadingScreen.scss";
import { LoadingScreenProps } from "../../types/Props";
import { LoadingComponent } from "./LoadingComponent";

export const LoadingScreen = (props: LoadingScreenProps): JSX.Element => {
	const { ended } = props;
	const containerRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if (ended) {
			containerRef.current.style.display = "none";
		}
	}, [ended]);

	return (
		<>
			<div className="loading-container" ref={containerRef}>
				<LoadingComponent ended={ended} />

				<div className="loading-screen-mask"></div>
			</div>
		</>
	);
};
