import { createContext, useContext, useEffect, useState } from "react";

import { AuthContext } from "./AuthProvider";
import { historyPlacemtesAPI } from "../../utilities/network/api";
import { NetworkContext } from "./NetworkProvider";
import { HistoryPlacemtesAPIResponse } from "../../types/APIResponse";
import { StatsProviderData } from "../../types/Providers";
import { DotaUserLeaderboardHistoryData, FortniteUserLeaderboardHistoryData, HistoryBreakpointPercentiles } from "../../types/Leaderboard";
import { PlacementMap } from "../../types/Histories";

export const StatsContext = createContext<StatsProviderData | null>(null);
export const StatsProvider = (props): JSX.Element => {
	const children = props.children;

	const [historyPlacements, setHistoryPlacements] = useState([] as DotaUserLeaderboardHistoryData[] | FortniteUserLeaderboardHistoryData[]);
	const [historyPercentiles, setHistoryPercentiles] = useState([] as HistoryBreakpointPercentiles[]);
	const [historyNumberOfPlayer, setHistoryNumberOfPlayer] = useState([] as number[]);
	const [historyPlacementsGraphData, setHistoryPlacementsGraphData] = useState([]);
	const [mapPlacements, setMapPlacements] = useState(new Map<string, PlacementMap>());
	const [maxDailyPointsValue, setMaxDailyPointsValue] = useState(0);
	const [hasPlayed, setHasPlayed] = useState(false);

	const { profile } = useContext(AuthContext);
	const { makeGet } = useContext(NetworkContext);

	const loadHistoryData = async (): Promise<void> => {
		try {
			if (profile == null) return;
			if (profile.favouriteGame == null) {
				return;
			}
			const fullUrl = `${historyPlacemtesAPI}?_token=${profile.idToken}&game=${profile.favouriteGame}`;
			const {
				data: { historyPlacements, hasPlayed, historyPercentiles, historyNumberOfPlayer },
			} = await makeGet<HistoryPlacemtesAPIResponse>(fullUrl);
			setHistoryPlacements(historyPlacements);
			setHasPlayed(hasPlayed);
			setHistoryPercentiles(historyPercentiles);
			setHistoryNumberOfPlayer(historyNumberOfPlayer);
		} catch (err) {
			console.log(err);
		}
	};

	const buildStatsToShowInGraph = () => {
		if (historyPlacements == null || historyPlacements.length === 0) {
			return;
		}
		if (historyPercentiles == null || historyPercentiles.length === 0) {
			return;
		}

		let historyPlacementsGraphData = [];
		let i = 0;

		let historyPercentilesOrdered = [] as HistoryBreakpointPercentiles[];
		let historyNumberOfPlayerOrdered = [] as number[];

		let maxDailyPointsValue = 0;
		let mapPlacements = new Map<string, PlacementMap>();
		Object.keys(historyPercentiles).map((key) => {
			historyPercentilesOrdered.push(historyPercentiles[key]);
			historyNumberOfPlayerOrdered.push(historyNumberOfPlayer[key]);
		});

		Object.keys(historyPlacements).map((key) => {
			const date = new Date(parseInt(historyPlacements[key].date));
			let day = date.getDate();

			let month = date.getMonth() + 1;

			let year = date.getFullYear();
			let UTCdate = day + "/" + month + "/" + year + " " + date.toLocaleTimeString();

			if (historyPercentilesOrdered[i].necessaryDailyPointsForFirstPercentile > maxDailyPointsValue) {
				let newMaxValue = historyPercentilesOrdered[i].necessaryDailyPointsForFirstPercentile + 20;
				newMaxValue /= 10;
				newMaxValue = Math.ceil(newMaxValue) * 10;

				maxDailyPointsValue = newMaxValue;
			}
			let dailyPercentile = "";

			switch (historyPlacements[key].percentile) {
				case 0.01:
					dailyPercentile = "Top 1%";
					break;

				case 0.09:
					dailyPercentile = "Top 10%";
					break;

				case 0.15:
					dailyPercentile = "Top 25%";
					break;

				case 1:
				case -1:
					dailyPercentile = "None";
					break;
			}

			if (profile != null && profile.isPremium) {
				mapPlacements.set(UTCdate, {
					dailyPoints: historyPlacements[key].dailyPoints,
					playersInLeaderboard: historyNumberOfPlayerOrdered[i],
					percentile: historyPlacements[key].percentile,
					dailyPercentile: dailyPercentile,
				});
				historyPlacementsGraphData.push({
					name: UTCdate,
					dailyPoints: historyPlacements[key].dailyPoints,
					firstPercentile: historyPercentilesOrdered[i].necessaryDailyPointsForFirstPercentile,
					secondPercentile: historyPercentilesOrdered[i].necessaryDailyPointsForSecondPercentile,
					thirdPercentile: historyPercentilesOrdered[i].necessaryDailyPointsForThirdPercentile,
				});
			} else {
				mapPlacements.set(UTCdate, { dailyPoints: 0, playersInLeaderboard: 0, percentile: 0, dailyPercentile: "10%" });
				historyPlacementsGraphData.push({
					name: UTCdate,
					dailyPoints: 0,
					firstPercentile: 0,
					secondPercentile: 0,
					thirdPercentile: 0,
				});
			}
			i++;
		});

		setMapPlacements(mapPlacements);
		setHistoryPlacementsGraphData(historyPlacementsGraphData.reverse());
		setMaxDailyPointsValue(maxDailyPointsValue);
	}

	useEffect(() => {
		loadHistoryData();
	}, [profile]);

	useEffect(() => {
		if(historyPercentiles != null)
			buildStatsToShowInGraph();
	}, [historyPercentiles])

	const statsProviderData: StatsProviderData = {
		hasPlayed,
		historyPlacements,
		historyPercentiles,
		historyNumberOfPlayer,
		historyPlacementsGraphData,
		mapPlacements,
		maxDailyPointsValue,
	};
	return <StatsContext.Provider value={statsProviderData}>{children}</StatsContext.Provider>;
};
