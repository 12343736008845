import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { HeroAnimation } from "../../../Javascript/HeroAnimation";
import "../../../styles/home/aboutUs/TakePartInOurStory.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const TakePartInOurStory = () => {
	const { t } = useTranslation("landing");
	const navigate = useNavigate();
	const [highlightedSection, setHighlightedSection] = useState<number>(0);

	useEffect(() => {
		const handleScroll = () => {
			const sections = document.querySelectorAll(".text-section-take-part-in-out-story");
			for (let i = 0; i < sections.length; i++) {
				const section = sections[i] as HTMLElement;
				const rect = section.getBoundingClientRect();
				const windowHeight = window.innerHeight || document.documentElement.clientHeight;
				const distanceFromBottom = windowHeight - rect.bottom;
				if (distanceFromBottom > 0 && distanceFromBottom >= windowHeight * 0.2) {
					setHighlightedSection(i);
				}
			}
		};

		document.body.addEventListener("scroll", handleScroll);
		HeroAnimation("#gradient-canvas-take-part-in-our-story");

		return () => {
			document.body.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleClick = () => {
		navigate("/");
		setTimeout(() => {
			const e = document.getElementById("landing-signup-container");
			if (e != null) {
				e.scrollIntoView({ behavior: "smooth" });
			}
		}, 1);
	};

	return (
		<>
			<Grid container justifyContent="center" className="take-part-in-our-story-container" sx={{ zIndex: 1000 }} columns={16}>
				<Grid item xs={14} sm={12} sx={{ marginBottom: { xs: 10, sm: 40 } }}>
					<Grid container justifyContent="flex-start">
						<Grid item xs={12} sm={10} xl={7} display="flex" flexDirection="column" rowGap={4}>
							<Typography
								variant="h3"
								className={`text-section-take-part-in-out-story ${
									highlightedSection >= 0 ? "highlighted" : ""
								}  story-telling-text  gradient-text-conatainer`}>
								{t("aboutus.leaderboard.section#1")}
								<span className="gradient-text">{t("performance")}</span>
								{t("aboutus.leaderboard.section#4")} {t("aboutus.how.it.works.competitive.career#1")}
								<span className="gradient-text">{t("aboutus.how.it.works.competitive.career#2")}</span>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={16} sm={15} className="take-part-in-our-story-box">
					<div id="canvas-container-take-part-in-our-story">
						<div className="canvas-wrapper">
							<canvas id="gradient-canvas-take-part-in-our-story"></canvas>
						</div>
					</div>
					<Typography className="take-part-in-our-story-box-title">Take part in our journey</Typography>
					<button className="take-part-in-our-story-box-button" onClick={handleClick}>
						{t("button.join.us")}
					</button>
				</Grid>
			</Grid>
		</>
	);
};
