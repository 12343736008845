import { useContext, useEffect } from "react";
import { FirebaseContext } from "../../../Providers/FirebaseProvider";

import { Button, Grid } from "@mui/material";
import $ from "jquery";
import { LeaderboardButtonsProps } from "../../../../types/Props";

export const LeaderboardButtons = (props: LeaderboardButtonsProps): JSX.Element => {
	const { myStorageUrl } = useContext(FirebaseContext);

	useEffect(() => {
		switch (props.my_swiper.activeIndex) {
			case 0:
				$(".fortnite-button-leaderboards").removeClass("opacity");
				$(".dota2-button-leaderboards").addClass("opacity");
				break;
			case 1:
				$(".dota2-button-leaderboards").removeClass("opacity");
				$(".fortnite-button-leaderboards").addClass("opacity");
				break;
			case 2:
				$(".dota2-button-leaderboards").removeClass("opacity");
				$(".fortnite-button-leaderboards").removeClass("opacity");
				break;
		}
	}, [props.my_swiper.activeIndex]);
	return (
		<>
			<div className="game-box-in-leaderboard">
				<Button onClick={() => props.my_swiper.slideTo(0)} className={"dota2-button-leaderboards"}>
					<Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
						<Grid item xs={4}>
							<img src={`${myStorageUrl}images/dota2logo.png`} alt=""></img>
						</Grid>
						<Grid item xs={8}>
							Dota2
						</Grid>
					</Grid>
				</Button>
				<Button onClick={() => props.my_swiper.slideTo(1)} className={"fortnite-button-leaderboards"}>
					<Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
						<Grid item xs={4}>
							<img src={`${myStorageUrl}images/fortnite-logo.png`}></img>
						</Grid>
						<Grid item xs={8}>
							Fortnite
						</Grid>
					</Grid>
				</Button>
			</div>
		</>
	);
};
