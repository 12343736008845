import { useEffect } from "react";

import { Button, Grid } from "@mui/material";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { StatsButtonsProps } from "../../../types/Props";

export const StatsButtons = (props: StatsButtonsProps) => {
	const { t } = useTranslation("dashboard");
	useEffect(() => {
		switch (props.my_swiper.activeIndex) {
			case 0:
				$(".live-leaderboard-button-stats").removeClass("opacity");
				$(".daily-placements-button-stats").addClass("opacity");
				break;
			case 1:
				$(".daily-placements-button-stats").removeClass("opacity");
				$(".live-leaderboard-button-stats").addClass("opacity");
				break;
		}
	}, [props.my_swiper.activeIndex]);
	return (
		<>
			<div className="game-box-in-leaderboard">
				<Button onClick={() => props.my_swiper.slideTo(0)} className={"daily-placements-button-stats"}>
					<Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
						<Grid item xs={12}>
							{t("daily placements")}
						</Grid>
					</Grid>
				</Button>
			</div>
		</>
	);
};
