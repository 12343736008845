import "react-tabs/style/react-tabs.css";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import "swiper/components/navigation/navigation.min.css";
import "../../styles/DashboardTournamet.scss";
import "../../styles/DashboardHome.scss";

import { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { LoadingScreen } from "../Loading/LoadingScreen";
import { LeaderboardsContainer } from "./Home/Leaderboard/LeaderboardsContainer";
import { AuthContext } from "../Providers/AuthProvider";

import { LeaderboardContext } from "../Providers/LeaderboardProvider";
import { QuestContainer } from "./Quests/QuestContainer";
import { QuestProgressionContainer } from "./Quests/Premium/QuestProgressionContainer";
import { Stats } from "./Stats/Stats";
import { DashboardHomeProps } from "../../types/Props";
import { FooterLanding } from "../home/landing/FooterLanding";
import { SliderHomePage } from "./Home/SliderHomepage";

export const DashboardHome = (props: DashboardHomeProps): JSX.Element => {
	const { profile } = useContext(AuthContext);
	const { initialLeaderboardLoaded } = useContext(LeaderboardContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (profile && initialLeaderboardLoaded) {
			setLoading(false);
		}
	}, [profile, initialLeaderboardLoaded]);

	return (
		<>
			{loading ? (
				<LoadingScreen ended={!loading} />
			) : (
				<>
				 	{/* This will be the new Slider for the home page */}
					<SliderHomePage />
					<Box marginTop={'4em'} component={"div"} className="main" id="main-container-home" position={{ xs: "static", md: "relative" }}>
						<div className="col-md-12">
							<Grid container justifyContent="center">
								<Grid item xs={12} md={10} lg={9}>
									<Grid container>
										<Grid item xs={12}>
											<Grid container>
												<Grid
													item
													xs={12}
													md={7}
													lg={7}
													xl={8}
													justifyContent="center"
													style={{ display: "flex", paddingBottom: "30px" }}
												>
													<LeaderboardsContainer isLiveLeaderboard={false} />
												</Grid>
												<Grid
													item
													xs={12}
													md={5}
													lg={5}
													xl={4}
													className="quest-continer-box"
													style={{ paddingTop: "30px", paddingBottom: "30px", paddingLeft: "1em" }}
												>
													<QuestContainer />
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} sx={{ marginBottom: "2em" }}>
											<QuestProgressionContainer />
										</Grid>
										<Grid item xs={12} sx={{ marginBottom: "2em" }} className="stats-container">
											<Stats />
										</Grid>
									</Grid>
								</Grid>
							</Grid>

						</div>
						<div style={{ position: 'relative', marginBottom: '2em' }}>
							<FooterLanding />
						</div>
					</Box>

				</>
			)}
		</>
	);
};
