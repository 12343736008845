import { Grid, createTheme, ThemeProvider } from "@mui/material";
import "../../styles/home/aboutUs/AboutUs.scss";
import { Header } from "../../components/HomepageComponents/Header";
import { Team } from "../../components/home/aboutUs/Team";
import { HowWeStarted } from "../../components/home/aboutUs/HowWeStarted";
import { HowItWorks } from "../../components/home/aboutUs/HowItWorks";
import "../../styles/home/Text.scss";
import { Title } from "../../components/home/aboutUs/Title";
import { HowToEarn } from "../../components/home/aboutUs/HowToEarn";
import { TakePartInOurStory } from "../../components/home/aboutUs/TakePartInOurStory";
import { FooterLanding } from "../../components/home/landing/FooterLanding";
//TODO :make this import "../../styles/home/Text.scss"; just one import in root

declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		tablemedium: true;
		md: true;
		lgsmall: true;
		lg: true;
		largemedium: true;
		xl: true;
		xxl: true;
		xxxl: true;
	}
}

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 700,
			tablemedium: 800,
			md: 900,
			lgsmall: 1000,
			lg: 1200,
			largemedium: 1350,
			xl: 1536,
			xxl: 1900,
			xxxl: 2500,
		},
	},
});

export const AboutUs = () => {
	return (
		<>
			<ThemeProvider theme={theme}>
				<Grid container>
					<Grid item xs={12}>
						<Header />
					</Grid>
				</Grid>
				<Grid container className="about-us-container" id="page-container">
					<div id="first-bubble-page-background"></div>
					<div id="second-bubble-page-background"></div>
					<div id="third-bubble-page-background"></div>
					<div id="fourth-bubble-page-background"></div>
					<Grid item xs={12} id="about-us-title">
						<Title />
					</Grid>
					<Grid item xs={12} className="section">
						<HowWeStarted />
					</Grid>
					<Grid item xs={12} className="section">
						<Team />
					</Grid>

					<Grid item xs={12} className="section">
						<HowToEarn />
					</Grid>
					<Grid item xs={12} className="section">
						<HowItWorks />
					</Grid>
					<Grid item xs={12} className="section">
						<TakePartInOurStory />
					</Grid>
					<Grid item xs={12}>
						<FooterLanding />
					</Grid>
				</Grid>
			</ThemeProvider>
		</>
	);
};
