import { Grid, createTheme, ThemeProvider } from "@mui/material";
import { GenericProps } from "../../types/Props";
import { useEffect, useState } from "react";
import "../../styles/Landing.scss";
import { Header } from "../../components/HomepageComponents/Header";
import { Vision } from "../../components/home/landing/Vision";
import $ from "jquery";
import { How } from "../../components/home/landing/How";
import { Rewards } from "../../components/home/landing/Rewards";
import { WhyUs } from "../../components/home/landing/WhyUs";
import { FomoSection } from "../../components/home/landing/FomoSection";

import "../../styles/home/Text.scss";
import { FooterLanding } from "../../components/home/landing/FooterLanding";

import "../../styles/home/Accordion.scss";
import { OurPartners } from "../../components/home/landing/OurPartners";
import { GameHubLanding } from "../../components/home/landing/GameHub/GameHubLanding";
import { SignupContainer } from "../../components/home/landing/Signup/SignupContainer";
declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		tablemedium: true;
		md: true;
		lgsmall: true;
		lg: true;
		largemedium: true;
		xl: true;
		xxl: true;
		xxxl: true;
	}
}

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 700,
			tablemedium: 800,
			md: 900,
			lgsmall: 1000,
			lg: 1200,
			largemedium: 1350,
			xl: 1536,
			xxl: 1900,
			xxxl: 2500,
		},
	},
});

export const Landing = (props: GenericProps) => {
	const [scrollPosition, setScrollPosition] = useState<number>(0);
	function isInViewport(el: Element) {
		const rect = el.getBoundingClientRect();
		if (window.innerWidth < 1200) return rect.top + rect.height / 2 < window.innerHeight && rect.bottom >= 0;
		return rect.top >= 0 && rect.bottom < window.innerHeight;
	}
	useEffect(() => {
		const visionStorm = $("#vison-estorm-title");
		let zoom = 1;
		const handleScroll = (e) => {
			// if (e.deltaY < 0 && zoom >= 3) visionStorm.css("transform", `scale(${(zoom -= 2)})`);
			// else if (e.deltaY > 0 && zoom < 50) visionStorm.css("transform", `scale(${(zoom += 2)}`);
			// console.log(376 * zoom, window.innerWidth);
			// if (376 * zoom > window.innerWidth * 5) {
			// 	document.body.style.overflow = "scroll";
			// 	visionStorm.css("display", "none");
			// 	console.log("AHAHA");
			// } else if (376 * zoom < window.innerWidth * 5 && isInViewport(visionStorm[0])) {
			// 	document.body.style.overflow = "hidden";
			// 	visionStorm.css("display", "block");
			// }
		};
		document.body.addEventListener("wheel", handleScroll);

		return () => {
			document.body.removeEventListener("wheel", handleScroll);
		};
	}, []);

	return (
		<>
			<ThemeProvider theme={theme}>
				<Grid container>
					<Grid item xs={12}>
						<Header />
					</Grid>
				</Grid>
				<Grid container className="landing-container" justifyContent="center" alignItems="center" id="page-container">
					<Grid item xs={12} className="vision-section" display="flex">
						<Vision />
					</Grid>
					<Grid item xs={12} my={3} className="landing-section" display="flex" justifyContent="center">
						<How />
					</Grid>
					<Grid item xs={12} my={3} className="landing-section" display="flex" justifyContent="center" sx={{ background: "#0F0721" }}>
						<Rewards />
					</Grid>
					<Grid item xs={12} my={3} className="landing-section" display="flex" justifyContent="center">
						<WhyUs />
					</Grid>
					<Grid item xs={12} my={3} display="flex" justifyContent="center" sx={{ background: "#0F0721" }}>
						<FomoSection />
					</Grid>
					<Grid item xs={12} my={10} display="flex" justifyContent="center">
						<GameHubLanding />
					</Grid>
					<Grid
						item
						xs={12}
						my={3}
						display="flex"
						justifyContent="center"
						sx={{
							background: "linear-gradient(90deg, rgba(171, 0, 199, 0.15) 0%, rgba(0, 117, 255, 0.15) 100.01%)",
							marginBottom: 0,
							marginTop: 0,
						}}
					>
						<OurPartners />
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ background: "linear-gradient(90deg, rgba(171, 0, 199, 0.25) 0%, rgba(0, 117, 255, 0.25) 100.01%)" }}
						className="sign-up-section"
					>
						<SignupContainer />
					</Grid>
					<Grid item xs={12}>
						<FooterLanding />
					</Grid>
				</Grid>
			</ThemeProvider>
		</>
	);
};
