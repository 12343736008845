import { createContext, useContext } from "react";
import { UserAPI, handleUserPromoCodeAPI, updateFavouriteGameAPI } from "../../utilities/network/api";
import { AuthContext } from "./AuthProvider";
import { NetworkContext } from "./NetworkProvider";
import { AddPromoCodeToUserResponse, UpdateFavouriteGameAPIResponse } from "../../types/APIResponse";
import { UserProviderData } from "../../types/Providers";
import { gamesInStorm } from "../../utilities/costants/genericCostants";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "./SnackbarProvider";

export const UserContext = createContext<UserProviderData | null>(null);
export const UserProvider = (props): JSX.Element => {
	const children = props.children;
	const { profile } = useContext(AuthContext);
	const { makePost } = useContext(NetworkContext);
	const { openSnackbar } = useSnackbar();
	const { t } = useTranslation(["dashboard"]);
	const { t: tResponses } = useTranslation(["responses"]);
	const gamesAllowed = [gamesInStorm.dota, gamesInStorm.fortnite, ""];

	/**
	 *
	 * @param {string} game
	 * @param {bool} checked : if checked is false then the user is disabling his favourite game, we need to handle this logic in backend
	 * @returns
	 */
	const changeFavouriteGame = async (game: string): Promise<string> => {
		let returnMessage: string;
		if (game === undefined || !gamesAllowed.includes(game)) {
			console.error(`The selected game is not valid. Choose between: ${gamesAllowed}`);
			return;
		}
		if (game === profile.favouriteGame) {
			return t("alreadyFavouriteGame");
		}

		try {
			const {
				data: { message },
			} = await makePost<UpdateFavouriteGameAPIResponse>(`${updateFavouriteGameAPI}`, {
				game: game,
				_token: `Bearer ${profile.idToken}`,
			});
			returnMessage = message;
		} catch (error) {
			console.error(`Something went wrong while changing the favourite game`);
			returnMessage = error.response.data;
		}
		return returnMessage;
	};

	const handleUserPromoCode = async (email: string, handle: "add" | "disable" | "enable"): Promise<void> => {
		try {
			if (email == null || email == "") {
				openSnackbar("Error", "Please specify an email", "error");
				return;
			}
			const response = await makePost<AddPromoCodeToUserResponse>(`${handleUserPromoCodeAPI}`, {
				email: email,
				handle: handle,
				_token: `Bearer ${profile.idToken}`,
			});
			const responseKey = response.data.i18nKey;
			let message = responseKey != null ? tResponses(responseKey) : response.data.message;
			if (response != null && response.status == 200) {
				openSnackbar("Success", message, "success");
			} else {
				if (response.data.message != null) {
					openSnackbar("Error", message, "error");
				} else {
					openSnackbar("Error", "Something went wrong", "error");
				}
			}
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		}
	};

	const handleAddPremiumToUser = async (email: string, premiumEndDateInUnixTime: number): Promise<void> => {
		try {
			if (email == null || email === "") {
				openSnackbar("Error", "Please specify an email", "error");
				return;
			}
			const response = await makePost(`${UserAPI.Admin.addPremium}`, {
				email: email,
				premiumEndDate: premiumEndDateInUnixTime,
				_token: `Bearer ${profile.idToken}`,
			});
			const responseKey = response.data.i18nKey;
			let message = responseKey != null ? tResponses(responseKey) : response.data.message;
			if (response != null && response.status == 200) {
				openSnackbar("Success", message, "success");
			} else {
				if (response.data.message != null) {
					openSnackbar("Error", message, "error");
				} else {
					openSnackbar("Error", "Something went wrong", "error");
				}
			}
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		}
	};

	const handleRemovePremiumFromUser = async (uid: string): Promise<void> => {
		try {
			if (uid == null || uid === "") {
				openSnackbar("Error", "Please specify an uid", "error");
				return;
			}
			const response = await makePost(`${UserAPI.Admin.removePremium}`, {
				uid: uid,
				_token: `Bearer ${profile.idToken}`,
			});
			const responseKey = response.data.i18nKey;
			let message = responseKey != null ? tResponses(responseKey) : response.data.message;
			if (response != null && response.status == 200) {
				openSnackbar("Success", message, "success");
			} else {
				if (response.data.message != null) {
					openSnackbar("Error", message, "error");
				} else {
					openSnackbar("Error", "Something went wrong", "error");
				}
			}
		} catch (error) {
			console.log(error);
			if (error) {
				openSnackbar("Error", error.message, "error");
			}
		}
	};

	const userProviderData: UserProviderData = {
		changeFavouriteGame,
		handleUserPromoCode,
		handleAddPremiumToUser,
		handleRemovePremiumFromUser,
	};

	return <UserContext.Provider value={userProviderData}>{children}</UserContext.Provider>;
};
