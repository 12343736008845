import { Card, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { QuestButton } from "./QuestButton";
import { DisclamerHover } from "../DisclamerHover";

/**
 * @dev The goal of this component is to contain all the quest buttons and show
 *      to the logged user the daily quest that he completed.
 * @param {*} props
 */
const useStyles = makeStyles({
	root: {
		"&.MuiPaper-root": {
			width: "100%",
			background: "black",
			border: "none",
			marginRight: "0px",
		},
	},
});

export const QuestContainer = (): JSX.Element => {
	const classes = useStyles(useStyles);
	const { t } = useTranslation("dashboard");

	const [disclamerOpen, setDisclamerOpen] = useState(false);

	const htmlHover = <>{t("quest explanation")}</>;
	return (
		<Card className={`quest-container ${classes.root}`}>
			<Grid container alignItems={"center"} justifyContent={"center"} direction="row">
				<Grid item xs={12}>
					<Grid container justifyContent={"space-between"}>
						<Grid item xs={5}>
							<h3>{t("quest")}</h3>
						</Grid>
						<Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
							<DisclamerHover
								placement={"bottom-end"}
								htmlHover={htmlHover}
								disclamerOpen={disclamerOpen}
								setDisclamerOpen={setDisclamerOpen}></DisclamerHover>
						</Grid>
					</Grid>
				</Grid>
				<hr className="border-line" />
				<Grid container alignItems={"center"} justifyContent={"center"} direction="row" sx={{ width: "auto" }}>
					<QuestButton
						percentile={0.01}
						title={t("diamond")}
						subtitle={t("diamond subtitle")}
						color={"button-quest-first-background"}
						freemiumReward={t("random.rewards.not.guaranteed")}
						premiumReward={"60 Bolts + XP boost"}
					/>
					<QuestButton
						percentile={0.09}
						title={t("platinum")}
						subtitle={t("platinum subtitle")}
						color={"button-quest-second-background"}
						freemiumReward={t("random.rewards.not.guaranteed")}
						premiumReward={"7 Bolts + XP boost"}
					/>
					<QuestButton
						percentile={0.15}
						title={t("golden")}
						subtitle={t("golden subtitle")}
						color={"button-quest-third-background"}
						premiumReward={"4 Bolts + XP boost"}
						isPremiumOnly={true}
					/>
				</Grid>
			</Grid>
		</Card>
	);
};
