import { createContext, useContext, useEffect, useState } from "react";
import { NetworkContext } from "./NetworkProvider";
import { leaderboardAPI } from "../../utilities/network/api";
import { AuthContext } from "./AuthProvider";
import { AuthProviderData, LeaderboardProviderData, NetworkProviderData } from "../../types/Providers";
import { Leaderboards, PlayerLeaderboardDota, PlayerLeaderboardFortnite } from "../../types/Leaderboard";
import { LeaderboardAPIResponse } from "../../types/APIResponse";
import { gamesInStorm } from "../../utilities/costants/genericCostants";

export const LeaderboardContext = createContext<LeaderboardProviderData | null>(null);
export const LeaderboardProvider = (props): JSX.Element => {
	const children = props.children;
	const { makeGet } = useContext(NetworkContext) as NetworkProviderData;
	const { profile } = useContext(AuthContext) as AuthProviderData;

	const [initialLeaderboardLoaded, setInitialLeaderboardLoaded] = useState(false);
	const [standardLeaderboard, setstandardLeaderboard] = useState({} as Leaderboards);
	const [standardLiveLeaderboard, setstandardLiveLeaderboard] = useState({} as Leaderboards);

	//change use State of dailyPlayer from { totalPlayers: 0 } to 0
	const [dailyPlayers, setDailyPlayers] = useState(0);
	const [dailyPlayersFortnite, setDailyPlayersFortnite] = useState(0);

	const [dailyLivePlayers, setDailyLivePlayers] = useState(0);
	const [dailyLivePlayersFortnite, setDailyLivePlayersFortnite] = useState(0);

	const [loggedUserInLeader, setLoggedUserInLeader] = useState({} as PlayerLeaderboardDota | PlayerLeaderboardFortnite);
	const [loggedUserInLiveLeader, setLoggedUserInLiveLeader] = useState({} as PlayerLeaderboardDota | PlayerLeaderboardFortnite);

	const [currentPageInLeaderboardDota, setCurrentPageInLeaderboardDota] = useState(1);
	const [currentPageInLiveLeaderboardDota, setCurrentPageInLiveLeaderboardDota] = useState(1);

	const numberOfPlayersInLeaderboard = 5;

	useEffect(() => {
		(async () => {
			await LoadLeaderboard();
		})();
	}, [profile?.idToken, profile != null && profile.isPremium]);

	const LoadLeaderboard = async (): Promise<void> => {
		if (profile != null) {
			let standardLeaderboards = { dota2: [], fortnite: [] };

			const dotaLeaderboard = await getLeaderboardPaginated(0, gamesInStorm.dota, false);
			standardLeaderboards.dota2 = dotaLeaderboard.leaderboards as PlayerLeaderboardDota[];

			const fortniteLeaderboard = await getLeaderboardPaginated(0, gamesInStorm.fortnite, false);
			standardLeaderboards.fortnite = fortniteLeaderboard.leaderboards as PlayerLeaderboardFortnite[];

			setstandardLeaderboard(standardLeaderboards);
			setInitialLeaderboardLoaded(true);

			setDailyPlayers(dotaLeaderboard.dailyPlayers);
			setDailyPlayersFortnite(fortniteLeaderboard.dailyPlayers);

			if (profile != null && profile.isPremium) {
				let standardLiveLeaderboards = { dota2: [], fortnite: [] };
				const dotaLiveLeaderboard = await getLeaderboardPaginated(0, gamesInStorm.dota, true);
				standardLiveLeaderboards.dota2 = dotaLiveLeaderboard.leaderboards as PlayerLeaderboardDota[];

				const fortniteLiveLeaderboard = await getLeaderboardPaginated(0, gamesInStorm.fortnite, true);
				standardLiveLeaderboards.fortnite = fortniteLiveLeaderboard.leaderboards as PlayerLeaderboardFortnite[];

				standardLiveLeaderboards.fortnite = await fetchLeaderboard(0, gamesInStorm.fortnite, true);
				setstandardLiveLeaderboard(standardLiveLeaderboards);
			}
		}
	};

	const fetchLeaderboard = async (
		startPos: number,
		game: string,
		liveLeaderboard: boolean
	): Promise<PlayerLeaderboardDota[] | PlayerLeaderboardFortnite[]> => {
		const { leaderboards, dailyPlayers, loggedPosInLeaderboard } = await getLeaderboardPaginated(startPos, game, liveLeaderboard);
		updateState(leaderboards, dailyPlayers, loggedPosInLeaderboard, game);
		return leaderboards;
	};

	const getLeaderboardPaginated = async (
		startPos: number,
		game: string,
		liveLeaderboard: boolean
	): Promise<{
		leaderboards: PlayerLeaderboardDota[] | PlayerLeaderboardFortnite[];
		dailyPlayers: number;
		loggedPosInLeaderboard: PlayerLeaderboardDota | PlayerLeaderboardFortnite;
	}> => {
		const fullUrl = `${leaderboardAPI}?startingPos=${startPos}&limit=${numberOfPlayersInLeaderboard}&_token=${profile.idToken}&game=${game}&liveLeaderboard=${liveLeaderboard}`;
		const {
			data: { leaderboard, dailyPlayers, loggedPosInLeaderboard },
		} = await makeGet<LeaderboardAPIResponse>(fullUrl);
		return { leaderboards: leaderboard, dailyPlayers, loggedPosInLeaderboard };
	};

	const updateState = (
		leaderboard: PlayerLeaderboardDota[] | PlayerLeaderboardFortnite[],
		dailyPlayers: number,
		loggedPosInLeaderboard: PlayerLeaderboardDota | PlayerLeaderboardFortnite,
		game: string
	): void => {
		let standardLeaderboards = {
			dota2: standardLeaderboard.dota2,
			fortnite: standardLeaderboard.fortnite,
		};
		if (game === gamesInStorm.fortnite) {
			standardLeaderboards.fortnite = leaderboard as PlayerLeaderboardFortnite[];
		} else if (game === gamesInStorm.dota) {
			standardLeaderboards.dota2 = leaderboard as PlayerLeaderboardDota[];
		}

		if (game === gamesInStorm.dota) {
			setDailyPlayers(dailyPlayers);
		} else if (game === gamesInStorm.fortnite) {
			setDailyPlayersFortnite(dailyPlayers);
		}

		initialLeaderboardLoaded && setstandardLeaderboard(standardLeaderboards);
		if (loggedPosInLeaderboard !== null && loggedPosInLeaderboard.position !== undefined) {
			setLoggedUserInLeader({ ...loggedPosInLeaderboard, game: game });
		}
	};

	const LeaderboardProviderData: LeaderboardProviderData = {
		initialLeaderboardLoaded: initialLeaderboardLoaded,
		standardLeaderboard: standardLeaderboard,
		fetchLeaderboard,
		loggedUserInLeader: loggedUserInLeader,
		maxNumberOfPlayersInLeaderboard: numberOfPlayersInLeaderboard,
		numberOfPlayersDota2: dailyPlayers,
		numberOfPlayersFortnite: dailyPlayersFortnite,
		currentPageInLeaderboardDota: currentPageInLeaderboardDota,
		setCurrentPageInLeaderboardDota,
		currentPageInLiveLeaderboardDota: currentPageInLiveLeaderboardDota,
		numberOfLivePlayersDota2: dailyLivePlayers,
		standardLiveLeaderboard: standardLiveLeaderboard,
		numberOfLivePlayersFortnite: dailyLivePlayersFortnite,
		loggedUserInLiveLeader: loggedUserInLiveLeader,
		setCurrentPageInLiveLeaderboardDota,
	};
	return <LeaderboardContext.Provider value={LeaderboardProviderData}>{children}</LeaderboardContext.Provider>;
};
