import { Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import "../../../styles/OurPartners.scss";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

const styles = makeStyles({
	image: {
		marginTop: '4em'
	}
})

export const OurPartners = () => {
	const { myStorageUrl } = useContext(FirebaseContext);
	const classes = styles();
	const { t } = useTranslation("dashboard");

	return (
		<Grid container id="our-partners-container" sx={{ paddingBottom: { xs: "4em", sm: "12em" }, paddingTop: { sx: "0em", sm: "8em" } }}>
			<Grid item xs={12} sx={{ paddingTop: "5em" }}>
				<Typography variant="h4">{t("our partners")}</Typography>
			</Grid>
			<Grid item md={2} />
			<Grid id="our-partners-i3p" item xs={6} md={4}>
				<a href="https://www.i3p.it/" target="_blank" rel="noreferrer">
					<img src={myStorageUrl + "vectors/i3PLogo.svg"} alt="partner" />
				</a>
			</Grid>
			<Grid className={classes.image} item xs={6} md={4} display="flex" justifyContent="center" alignItems="center">
				<a href="https://www.overwolf.com/" target="_blank" rel="noreferrer">
					<img style={{
						mixBlendMode: 'lighten',
						width: '70%'
					}} src={myStorageUrl + "images/overwolfLogo.png"} alt="partner" />
				</a>
			</Grid>
			<Grid item md={2} />
		</Grid>
	);
};
