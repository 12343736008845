import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Table } from "@mui/material";
import { makeStyles } from "@mui/styles";

function createData(
    id: string,
    party: string,
    provider: string,
    type: string,
    purpose: string,
    duration: string,
    info: string
) {
    return { id, party, provider, type, purpose, duration, info };
}

const rows = [
    createData('__paypal_storage__', 'Third', 'PayPal', 'Technical', 'Functional', '3 Months', 'Used to enable payments on the Website. The cookie is needed for a secure transaction via PayPal.'),
    createData('_GRECAPTCHA', 'Third', 'Google', 'Technical', 'Security', '3 Months', 'Used to enable risk analysis. It distinguishes between humans and robots. This is useful for the website in order to keep reports on the use of the site.'),
    createData('_grecaptcha', 'Third', 'Google', 'Technical', 'Security', '3 Months', 'Used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.'),
    createData('rc::a', 'Third', 'Google', 'Technical', 'Security', '3 Months', 'Used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.'),
    createData('rc::b', 'Third', 'Google', 'Technical', 'Security', '3 Months', 'Used to distinguish between humans and bots.'),
    createData('rc::c', 'Third', 'Google', 'Technical', 'Security', '3 Months', 'Used to distinguish between humans and bots.'),
    createData('rc::d-15#', 'Third', 'Google', 'Technical', 'Security', '3 Months', 'Used to distinguish between humans and bots.'),
    createData('rc::f', 'Third', 'Google', 'Technical', 'Security', '3 Months', 'Used to distinguish between humans and bots.'),
    createData('_fbp', 'Third', 'Facebook', 'Analytics', 'Marketing', '3 Months', 'To deliver advertisements when the Users are on Facebook or a digital platform powered by Facebook advertising after visiting the Website'),
    createData('firebase-heartbeat-database#firebase-heartbeat-store', 'Third', 'Google', 'Technical', 'Security', '3 Months', 'Used to detect spam and improve the security of the site.'),
    createData('firebaseLocalStorageDb#firebaseLocalStorage', 'Third', 'Google', 'Technical', 'Functional', '3 Months', 'Used to facilitate the notification function within the website. It allows the site\'s reporting team to notify the visitor when an event has been triggered in the application.'),
    createData('i18nextLng', 'First', 'E-Storm', 'Technical', 'Functional', '3 Months', 'Used to determine the visitor\'s preferred language. Allows the website to set the preferred language when visitor\'s return.'),
    createData('firebase-app-check-database#firebase-app-check-store', 'Third', 'Google', 'Technical', 'Security' ,'3 Months', '')
]

const styles = makeStyles({
    table: {
        '& .MuiTableCell-root': {
          color: 'white', 
        },
      }, 
})

export const CookiesTable = () => {
    const useStyles = styles();
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className={useStyles.table}>
                    <TableHead >
                        <TableRow >
                            <TableCell >ID Cookies</TableCell>
                            <TableCell align="right">Party</TableCell>
                            <TableCell align="right">Provider</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Purpose</TableCell>
                            <TableCell align="right">Duration</TableCell>
                            <TableCell align="right">Further information</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="right">{row.party}</TableCell>
                                <TableCell align="right">{row.provider}</TableCell>
                                <TableCell align="right">{row.type}</TableCell>
                                <TableCell align="right">{row.purpose}</TableCell>
                                <TableCell align="right">{row.duration}</TableCell>
                                <TableCell align="right">{row.info}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}