import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Title = () => {
	const { t } = useTranslation(['landing']);
	return (
		<>
			<Grid container justifyContent="center" alignItems="center">
				<Grid item xs={10} sm={8} lg={6}>
					<Typography variant="h3" className="gradient-text-conatainer about-us-title-text">
						<span className="gradient-text">{t('aboutus.title')}</span>
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};
