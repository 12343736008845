import { FormEvent, useEffect, useState } from "react";
import { InputTextProps } from "../../types/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

/** Component for InputText
 *  @arg {String} type The type for the input: 'password', 'text' or 'email
 *  @arg {String} id The id for the input component
 *  @arg {String} placeholder The placeholder for the input component
 *
 *  @example
 *      <Input type="text" placeholder="Username" id="idTest" />
 *      <Input type="password" placeholder="Password" id="idTest" />
 *      <Input type="email" placeholder="Email" id="idTest" />
 *
 *  */
export const InputText = ({ type, id, idErr, placeholder, fun, placeholderValue }: InputTextProps): JSX.Element => {
	const [inputType, setInputType] = useState("text");
	function inputChanged(e: FormEvent<HTMLInputElement>) {
		let input = e.currentTarget;
		if (input.value.trim() == "") {
			input.dataset.state = "empty";
		} else {
			input.dataset.state = "notEmpty";
		}
	}

	const togglePasswordVisibility = (): void => {
		if (inputType === "password") {
			setInputType("text");
		} else {
			setInputType("password");
		}
	};
	useEffect(() => {
		if (type == "password" || type == "text" || type == "email") setInputType(type);
	}, []);

	return (
		<>
			<div className="inputContainer">
				{fun != undefined ? (
					<input
						placeholder={placeholderValue !== undefined ? placeholderValue : ""}
						className="text-p"
						id={id}
						type={inputType}
						onChange={(e) => {
							inputChanged(e);
							fun(e);
						}}
					/>
				) : (
					<>
						<input
							placeholder={placeholderValue !== undefined ? placeholderValue : ""}
							className="text-p"
							id={id}
							type={inputType}
							onChange={inputChanged}
						/>
						{placeholder && <div className="labelPlaceholder text-p">{placeholder}</div>}
						{idErr && <label className="text-label error" id={idErr}></label>}
						{type === "password" && (
							<div onClick={togglePasswordVisibility} className="password-visibility-toggle-container">
								{inputType === "password" ? <FontAwesomeIcon icon={faEye as IconProp} /> : <FontAwesomeIcon icon={faEyeSlash as IconProp} />}
							</div>
						)}
					</>
				)}
			</div>
		</>
	);
};
