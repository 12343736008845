import { Typography } from "@mui/material"


const GradientTypographyBoxTitle = ({ content, variant, sx }: {
    content: string,
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | undefined,
    sx?:any
}) => {
    return (
        <Typography sx={{
            background: "linear-gradient(90deg, #ab00c7 0%, #0075ff 100.01%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: 700,
            fontSize: 18,
            whiteSpace: 'nowrap',
            sx
        }} variant={variant}  >
            {content}
        </Typography>
    )
}

export default GradientTypographyBoxTitle;