export function getTermsAndConditions(): string {
    
    const language = window.localStorage.getItem("language");
    if (language === "en") {
        return "terms_conditions_eng.pdf";
    } else if (language === "it") {
        return "termini e condizioni.pdf";
    } else {
        return "terms_conditions_eng.pdf";
    }
}

export function getPrivacyPolicies(): string {
    const language = window.localStorage.getItem("language");
    if (language === "en") {
        return "privacy_policies_eng.pdf";
    } else if (language === "it") {
        return "privacy_policies_ita.pdf";
    } else {
        return "privacy_policies_eng.pdf";
    }
}