import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

import config from "../../config-file.json";
import { createContext, useEffect, useState } from "react";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { FirebaseProviderData, MapEmulators } from "../../types/Providers";

export const FirebaseContext = createContext<FirebaseProviderData | null>(null);
export const FirebaseProvider = (props) => {
	const children = props.children;
	const [usingEmulators, setUsingEmulators] = useState(false);
	const [emulatorsConfig, setEmulatorsConfig] = useState({} as MapEmulators);
	const myApp = initializeApp(config[process.env.REACT_APP_ENV]["firebase-config"]);
	const myAuth = getAuth(myApp);
	const myFS = getFirestore(myApp);
	const myStorage = getStorage(myApp, process.env.REACT_APP_ENV.includes("PROD") ? `gs://images_gifs_videos_storm` : "gs://images_gifs_videos_storm_dev");
	const storagePictures = getStorage(myApp, config[process.env.REACT_APP_ENV]["firebase-config"]["storageBucket"]);
	const myFunctions = getFunctions(myApp);
	const myStorageUrl: string = config[process.env.REACT_APP_ENV]["url-my-storage"];
	const storagePicturesUrl: string = config[process.env.REACT_APP_ENV]["url-storage-pictures"];

	useEffect(() => {
		const shouldUseEmulator = process.env.REACT_APP_USING_EMULATOR === "TRUE" ? true : false;

		if (shouldUseEmulator) {
			let mapEmulators: MapEmulators = {} as MapEmulators;

			let FS_HOST = "localhost";
			let FS_PORT = 8088;

			if (FS_HOST && FS_PORT) {
				connectFirestoreEmulator(myFS, FS_HOST, FS_PORT);
				console.log(`firestore().useEmulator(${FS_HOST}, ${FS_PORT})`);
				mapEmulators.FS_HOST = FS_HOST;
				mapEmulators.FS_PORT = FS_PORT;
			}

			let AUTH_HOST = "localhost";
			let AUTH_PORT = 9099; // or whatever you set the port to in firebase.json
			if (AUTH_HOST && AUTH_PORT) {
				let AUTH_URL = `http://${AUTH_HOST}:${AUTH_PORT}`;
				console.log(`connectAuthEmulator(${AUTH_URL}, {disableWarnings: true})`);
				//    warns you not to use any real credentials -- we don't need that noise :)
				connectAuthEmulator(myAuth, AUTH_URL, { disableWarnings: true });

				mapEmulators.AUTH_HOST = AUTH_HOST;
				mapEmulators.AUTH_PORT = AUTH_PORT;
				mapEmulators.AUTH_URL = AUTH_URL;
			}

			let STORAGE_HOST = "localhost";
			let STORAGE_PORT = 5004;  // or whatever you have it set to in firebase.json
			if (STORAGE_HOST && STORAGE_PORT) {
			    console.log(`connectStorageEmulator(${STORAGE_HOST}, ${STORAGE_PORT})`);
			    connectStorageEmulator(myStorage, STORAGE_HOST, STORAGE_PORT);

			    mapEmulators.STORAGE_HOST = STORAGE_HOST;
			    mapEmulators.STORAGE_PORT = STORAGE_PORT;
			}

			setUsingEmulators(true);
			setEmulatorsConfig(mapEmulators);

			console.log("FIREBASE STARTUP: using Firebase emulator:", JSON.stringify(mapEmulators, null, 2));
		}
	}, [myAuth, myFS, myStorage]);

	const firebaseContextData: FirebaseProviderData = {
		usingEmulators,
		emulatorsConfig,
		myApp,
		myAuth,
		myFS,
		myStorage,
		myFunctions,
		storagePictures,
		myStorageUrl,
		storagePicturesUrl,
	};

	return <FirebaseContext.Provider value={firebaseContextData}>{children}</FirebaseContext.Provider>;
};
