import { useContext, useEffect, useState } from "react";
import { Box, Card, Grid, Skeleton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DashboardMarketProps } from "../../types/Props";
import "../../styles/DropDown.scss";
import "../../styles/DashboardMarket.scss";
import "react-widgets/styles.css";
import { FooterLanding } from "../home/landing/FooterLanding";
import { Inventory } from "../../types/Payments";
import { GemsPack } from "./Market/GemsPacks/GemsPack";
import { MarketContext } from "../Providers/MarketProvider";
import { Subscription } from "./Market/Subscription/Subscription";
import { GenericCard } from "./Market/GiftCards/GenericCard";
import { LaunchRounded } from "@mui/icons-material";

import estorm_logo from "../../assets/images/eStorm_logo.svg";
import { FirebaseContext } from "../Providers/FirebaseProvider";
import { useTranslation } from "react-i18next";

declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		tablemedium: true;
		md: true;
		lgsmall: true;
		lg: true;
		largemedium: true;
		xl: true;
		xxl: true;
		xxxl: true;
	}
}

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			tablemedium: 800,
			md: 900,
			lgsmall: 1000,
			lg: 1200,
			largemedium: 1350,
			xl: 1536,
			xxl: 1900,
			xxxl: 2500,
		},
	},
});

export const DashboardMarket = ({ name }: { name?: string }): JSX.Element => {
	const { getItemsFromDB } = useContext(MarketContext);
	const { i18n } = useTranslation();
	const { myStorageUrl } = useContext(FirebaseContext);
	const [items, setItems] = useState<Inventory.Items>(undefined);

	useEffect(() => {
		(async () => {
			const items = await getItemsFromDB();
			setItems(items);
		})();
	}, []);

	return (
		<>
			<div className="market-main">
				<ThemeProvider theme={theme}>
					{/* Add a blur effect on the page */}
					<Box
						sx={{
							position: "absolute",
							top: "-3dvh",
							width: "100%",
							py: "5rem",
							minHeight: "40dvh",
							backgroundColor: "rgba(0, 0, 0, 0.5)",
							zIndex: 100,
							backdropFilter: "blur(50px)",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							textAlign: "left",
						}}>
						{i18n.language === "it" ? (
							<>
								<span style={{ width: "58.5%", fontSize: "2.5rem" }}>
									<img src={estorm_logo} alt="eStorm Logo" style={{ width: "4%", marginRight: "2rem", paddingBottom: ".5rem" }} /> Il marketplace di
									eStorm tornerà con la versione 1.0.0!
								</span>
								<br />
								<span style={{ width: "50%", fontSize: "1.5rem" }}>
									🎉 Per celebrare la <b>Open Beta</b>, la Week of Glory sarà <i>gratuita</i> da Lunedì 18 Novembre 🎉
								</span>
								<span style={{ width: "50%" }}>
									Tutti potranno partecipare senza Bolt, provando eStorm in prima persona e scoprendo il potenziale di guadagno.
								</span>
								<br />
								<span style={{ width: "50%", fontSize: "1.5rem" }}>⚡ Ricompensa Accesso Anticipato ⚡</span>
								<span style={{ width: "50%" }}>
									Chi partecipa a questa fase della Week of Glory riceverà una ricompensa al lancio della versione 1.0.0 l'11 dicembre, durante le
									finali della eStorm Cup.
								</span>
								<br />
								<span style={{ width: "50%" }}>
									Per bug o feedback, apri un ticket nella{" "}
									<a href="https://discord.gg/7ghE8Xb52a" target="_blank" rel="noopener noreferrer">
										sezione eStorm{" "}
										<img src={`${myStorageUrl}vectors/discordico.svg`} alt="discordico" width="1.5%" style={{ paddingBottom: ".2rem" }} />
									</a>
									.
								</span>
								<br />
								<span style={{ width: "50%" }}>Che la tempesta abbia inizio, e che vincano i migliori! 🌩️💪</span>
								<br />
								<br />
								<span style={{ fontSize: "1.75rem" }}>
									<a href="https://www.overwolf.com/app/eStorm-eStorm" target="_blank" rel="noopener noreferrer">
										Scarica <LaunchRounded fontSize="inherit" />
									</a>{" "}
									la nostra app dall'Appstore Overwolf
								</span>
							</>
						) : (
							<>
								<span style={{ width: "58.5%", fontSize: "2.5rem" }}>
									<img src={estorm_logo} alt="eStorm Logo" style={{ width: "4%", marginRight: "2rem", paddingBottom: ".5rem" }} /> The eStorm
									marketplace returns with version 1.0.0!
								</span>
								<br />
								<span style={{ width: "50%", fontSize: "1.5rem" }}>
									🎉 To celebrate the <b>Open Beta</b>, Week of Glory will be{" "}
									<b>
										<i>free</i>
									</b>{" "}
									from Monday, November 18 🎉
								</span>
								<span style={{ width: "50%" }}>
									All users can join without bolts, getting a firsthand eStorm experience and a look at potential earnings.
								</span>
								<br />
								<span style={{ width: "50%", fontSize: "1.5rem" }}>⚡ Early Access Reward ⚡</span>
								<span style={{ width: "50%" }}>
									Everyone participating in this phase of Week of Glory will receive a reward at the launch of version 1.0.0 on December 11, during
									the eStorm Cup finals.
								</span>
								<br />
								<span style={{ width: "50%" }}>
									For bug reports or feedback, open a ticket in the{" "}
									<a href="https://discord.gg/7ghE8Xb52a" target="_blank" rel="noopener noreferrer">
										eStorm section{" "}
										<img src={`${myStorageUrl}vectors/discordico.svg`} alt="discordico" width="1.5%" style={{ paddingBottom: ".2rem" }} />
									</a>
									.
								</span>
								<br />
								<span style={{ width: "50%" }}>Let the storm begin, and may the best players win! 🌩️💪</span>
								<br />
								<br />
								<span style={{ fontSize: "1.75rem" }}>
									<a href="https://www.overwolf.com/app/eStorm-eStorm" target="_blank" rel="noopener noreferrer">
										Download <LaunchRounded fontSize="inherit" />
									</a>{" "}
									our app from Overwolf AppStore
								</span>
							</>
						)}
					</Box>

					<Grid container justifyContent="center" alignItems="center" gap={20} sx={{ marginTop: "3em" }} overflow="hidden" height="30dvh">
						{items === undefined ? (
							<Skeleton variant="rectangular" width="50%" height="300px" sx={{ bgcolor: "grey.700", borderRadius: "30px" }} animation={"pulse"} />
						) : (
							<>
								<GemsPack gemsPack={items.gemsPack} />
								<Card
									sx={{
										width: "20%",
										height: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<Box
										component="img"
										alt="Riot Logo"
										src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fgamepedia.cursecdn.com%2Flolesports_gamepedia_en%2F1%2F14%2FRiotGamesLogo.png%3Fversion%3Dc93be1510e36fc260274948d6718ea87&f=1&nofb=1&ipt=d7bcb33759b21b87d040f8b928d120635d38a6a695c64cc1dee8a867995c1ccf&ipo=images"
										sx={{ zIndex: 1 }}
										width="90%"
									/>
								</Card>
							</>
						)}
					</Grid>
				</ThemeProvider>
				<div style={{ position: "relative", marginBottom: "2em" }}>
					<FooterLanding />
				</div>
			</div>
		</>
	);
};
