import { Grid, Typography } from "@mui/material";
import "../../../styles/home/aboutUs/HowToEarn.scss";

import bolts_rain from "../../../assets/images/bolt_rain.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "../../Providers/FirebaseProvider";

export const HowToEarn = () => {
	const [boltsAnimation, setBoltsAnimation] = useState<boolean>(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const elementRef = useRef<HTMLDivElement>(null);
	const [animationPlayed, setAnimationPlayed] = useState<boolean>(false);
	const { t } = useTranslation("landing");
	const { myStorageUrl } = useContext(FirebaseContext);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					/**Make sure that the timeout time is equal to the animation time */
					if (entry.target.id === "bolts-rain-container") {
						if (entry.isIntersecting) {
							if (!entry.target.classList.contains("bolts-animation")) {
								entry.target.classList.add("bolts-animation");
								document.getElementById("how-to-earn-text-container").classList.add("bolts-animation");
								setTimeout(() => {
									setBoltsAnimation(true);
								}, 1000);
								observer.unobserve(entry.target);
							}
						} else {
							if (entry.target.classList.contains("bolts-animation")) {
								entry.target.classList.remove("bolts-animation");
								document.getElementById("how-to-earn-text-container").classList.remove("bolts-animation");
								setTimeout(() => {
									setBoltsAnimation(false);
								}, 1000);
								observer.unobserve(entry.target);
							}
						}
					}
				});
			},
			{ threshold: 0.5 }
		);

		if (containerRef.current) {
			observer.observe(containerRef.current);
		}

		return () => {
			if (containerRef.current) {
				observer.unobserve(containerRef.current);
			}
		};
	}, [boltsAnimation]);
	useEffect(() => {
		const handleMouseMovement = (e) => {
			const rect = e.target.getBoundingClientRect();
			const mouseX = e.clientX - rect.left;
			const mouseY = e.clientY - rect.top;

			e.target.style.setProperty("--mouse-x", `${mouseX}deg`);
			e.target.style.setProperty("--mouse-y", `${mouseY}deg`);
		};
		const panel = document.querySelector("#leaderboar-image-about-us");

		panel.addEventListener("mousemove", handleMouseMovement);

		return () => {
			panel.removeEventListener("mousemove", handleMouseMovement);
		};
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					/**Make sure that the timeout time is equal to the animation time */
					if (entry.isIntersecting) {
						if (!entry.target.classList.contains("appear-from-right")) {
							entry.target.classList.add("appear-from-right");
							setTimeout(() => {
								setAnimationPlayed(true);
							}, 2000);
							observer.unobserve(entry.target);
						}
					} else if (false) {
						if (entry.target.classList.contains("appear-from-right")) {
							entry.target.classList.add("disappear-from-right");
							entry.target.classList.remove("appear-from-right");
							setTimeout(() => {
								setAnimationPlayed(false);
								entry.target.classList.remove("disappear-from-right");
							}, 2000);
							observer.unobserve(entry.target);
						}
					}
				});
			},
			{ threshold: 0.5 }
		);

		if (elementRef.current) {
			observer.observe(elementRef.current);
		}

		return () => {
			if (elementRef.current) {
				observer.unobserve(elementRef.current);
			}
		};
	}, [animationPlayed]);
	const [highlightedSection, setHighlightedSection] = useState<number | null>(null);
	useEffect(() => {
		const handleScroll = () => {
			const sections = document.querySelectorAll(".text-section-how-to-earn");

			for (let i = 0; i < sections.length; i++) {
				const section = sections[i] as HTMLElement;
				const rect = section.getBoundingClientRect();
				const windowHeight = window.innerHeight || document.documentElement.clientHeight;
				const distanceFromBottom = windowHeight - rect.bottom;
				if (distanceFromBottom > 0 && distanceFromBottom / i >= windowHeight * 0.2) {
					setHighlightedSection(i);
				}
			}
		};

		document.body.addEventListener("scroll", handleScroll);

		return () => {
			document.body.removeEventListener("scroll", handleScroll);
		};
	}, []);
	return (
		<>
			<Grid
				container
				className="how-to-earn-container"
				justifyContent="center"
				sx={{ marginTop: { xs: 20, sm: 20 }, marginBottom: { xs: 0, sm: 20 } }}>
				<Grid item xs={11} sx={{ marginBottom: { xs: 0, sm: 30 } }}>
					<Grid container justifyContent="center" alignItems="center">
						<Grid item xs={12} md={6} className="bolts-rain-container" id="bolts-rain-container" ref={containerRef}>
							<div className="bolts-sign" id="bolts-sign-1">
								<div className="bolts-sign-container" style={{ "--bolts-delay": "0s", "--bolts-rotation": "0deg" } as React.CSSProperties}>
									<img src={bolts_rain} alt="bolts-sign" />
								</div>
							</div>
							<div className="bolts-sign" id="bolts-sign-2">
								<div className="bolts-sign-container" style={{ "--bolts-delay": "0.25s", "--bolts-rotation": "0deg" } as React.CSSProperties}>
									<img src={bolts_rain} alt="bolts-sign" />
								</div>
							</div>
							<div className="bolts-sign" id="bolts-sign-3" style={{ "--bolts-rotation": "-33.83deg" } as React.CSSProperties}>
								<div className="bolts-sign-container" style={{ "--bolts-delay": "0.5s" } as React.CSSProperties}>
									<img src={bolts_rain} alt="bolts-sign" />
								</div>
							</div>
							<div className="bolts-sign" id="bolts-sign-4" style={{ "--bolts-rotation": "81.79deg" } as React.CSSProperties}>
								<div className="bolts-sign-container" style={{ "--bolts-delay": "0.75s" } as React.CSSProperties}>
									<img src={bolts_rain} alt="bolts-sign" />
								</div>
							</div>
							<div className="bolts-sign" id="bolts-sign-5">
								<div className="bolts-sign-container" style={{ "--bolts-delay": "1s", "--bolts-rotation": "0deg" } as React.CSSProperties}>
									<img src={bolts_rain} alt="bolts-sign" />
								</div>
							</div>

							<div id="first-bubble-bolts-rain-background"></div>
							<div id="second-bubble-bolts-rain-background"></div>
							<div id="third-bubble-bolts-rain-background"></div>
							<div id="fourth-bubble-bolts-rain-background"></div>
						</Grid>
						<Grid item xs={12} md={6} id="how-to-earn-text-container" sx={{ zIndex: 20 }}>
							<Typography variant="h3" className="story-telling-text how-to-earn-text-first-section">
								{t("aboutus.how.to.earn.title#1")}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={11} mt={20}>
					<Grid container justifyContent="center" alignItems="center" sx={{ flexDirection: { xs: "column-reverse", sm: "row" } }}>
						<Grid item xs={12} sm={6} rowGap={4} display="flex" flexDirection="column" mt={5}>
							<Typography
								variant="h3"
								className={`text-section-how-to-earn ${
									highlightedSection >= 0 ? "highlighted" : ""
								}  story-telling-text  story-telling-text gradient-text-conatainer`}>
								{t("aboutus.how.to.earn.leaderboard.title#1")}
								<span className="gradient-text">Bolts </span>
								{t("aboutus.how.to.earn.leaderboard.title#2")}
								<span className="gradient-text"> {t("aboutus.how.to.earn.leaderboard.title#3")} </span>
								{t("aboutus.how.to.earn.leaderboard.title#4")}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4} ref={elementRef} id="about-us-image-container">
							<img
								id="leaderboar-image-about-us"
								className="leaderboard-image-about-us"
								src={`${myStorageUrl}images/landing/leaderboard_image.png`}
								alt="Depth Image"
							/>
							<div id="first-bubble-background"></div>
							<div id="second-bubble-background"></div>
							<div id="third-bubble-background"></div>
							<div id="fourth-bubble-background"></div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
