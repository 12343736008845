import { createContext, useContext, useEffect, useState } from "react";
import { NetworkContext } from "./NetworkProvider";
import { AuthContext } from "./AuthProvider";
import { AuthProviderData, NetworkProviderData, PaymentProviderData } from "../../types/Providers";
import { BoltsDiscount } from "../../types/Payments";
import { GemsPayemntAPIResponse } from "../../types/APIResponse";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { FirebaseContext } from "./FirebaseProvider";
import { useSnackbar } from "./SnackbarProvider";
import { useTranslation } from "react-i18next";

export const PaymentContext = createContext<PaymentProviderData | null>(null);
export const PaymentProvider = (props): JSX.Element => {
	const children = props.children;

	const [shouldListenToGiftcardRequest, setShouldListenToGiftcardRequest] = useState(false);
	const [checkoutIDForGiftCardListener, setCheckoutIDForGiftCardListener] = useState("");
	const [paymentCompleted, setPaymentCompleted] = useState(false);

	const { makePost } = useContext(NetworkContext) as NetworkProviderData;
	const { profile } = useContext(AuthContext) as AuthProviderData;
	const { myFS } = useContext(FirebaseContext);
	const { openSnackbar } = useSnackbar();
	const { t } = useTranslation("dashboard");
	const boltsValue = 1 / 18;
	//Minimum amount of bolts to be used for a purchase because Stripe needs at least a transaction with 0.50 cents
	const boltsForFiftyCents = 9;

	const discountDetails = (boltsToBeUsed: number): BoltsDiscount => {
		const boltsValue = 1 / 18;

		let boltsToEur = parseFloat((Math.floor(boltsToBeUsed * boltsValue * 100) / 100).toFixed(2));
		let boltsToTake = Number((Math.floor((boltsToEur / boltsValue) * 1000000) / 1000000).toFixed(6));

		return { boltsToTake: boltsToTake, boltsToEur: boltsToEur };
	};
	const maxBoltsDiscount = (boltPrice: number): number => {
		return boltPrice - boltsForFiftyCents;
	};

	const startListenerToGiftcardRequest = (id: string) => {
		setCheckoutIDForGiftCardListener(id);
		setShouldListenToGiftcardRequest(true);
	};
	const endListenerToGiftcardRequest = () => {
		setCheckoutIDForGiftCardListener("");
		setShouldListenToGiftcardRequest(false);
	};

	useEffect(() => {
		let unsub = null;
		if (shouldListenToGiftcardRequest) {
			const q = query(collection(myFS, "giftCardRequests"), where("orderId", "==", checkoutIDForGiftCardListener));
			unsub = onSnapshot(q, (querysnapshot) => {
				if (querysnapshot.docs.length > 0) {
					setPaymentCompleted(true);
					endListenerToGiftcardRequest();
				}
			});
		}

		return () => {
			return unsub && unsub();
		};
	}, [shouldListenToGiftcardRequest]);

	const paymentProviderData: PaymentProviderData = {
		discountDetails: discountDetails,
		maxBoltsDiscount: maxBoltsDiscount,
		startListenerToGiftcardRequest: startListenerToGiftcardRequest,
		endListenerToGiftcardRequest: endListenerToGiftcardRequest,
		setPaymentCompleted,
		boltsValue: boltsValue,
		boltsForFiftyCents: boltsForFiftyCents,
		paymentCompleted,
	};

	return <PaymentContext.Provider value={paymentProviderData}>{children}</PaymentContext.Provider>;
};
