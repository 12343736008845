import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Grid, Typography } from "@mui/material";
import { Inventory } from "../../../../types/Payments";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Providers/AuthProvider";
import { PaypalButton } from "../../Payments/PaypalButton";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "../../../Providers/FirebaseProvider";
import { CustomSelect } from "../../../Select/CustomSelect";
import { ICountry, countries } from "countries-list";
import { InputText } from "../../../Input/InputText";
import { MarketContext } from "../../../Providers/MarketProvider";

type ConfirmBuyDialogProps = {
	open: boolean;
	handleClose: () => void;
	item: Inventory.Item;
};

type Cart = {
	boltsGiven: number;
	price: number;
	uid: number;
	name: string;
	VAT: number;
	category: string;
};
const FISCAL_CODE_REGEX = /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;

const countryCodes = Object.keys(countries);
const countryNames = countryCodes.map((code) => (countries[code] as ICountry).name);

export const ConfirmBuyDialog = ({ open, handleClose, item }: ConfirmBuyDialogProps) => {
	const { profile } = useContext(AuthContext);
	const { categories } = useContext(MarketContext);
	const [countrySelected, setCountrySelected] = useState<string>("");
	const { myStorageUrl } = useContext(FirebaseContext);
	const { t } = useTranslation("dashboard");
	const [fiscalCode, setFiscalCode] = useState<string>("");
	const [showPaymentButtons, setShowPaymentButtons] = useState<boolean>(false);
	const [fiscalCodeIsValid, setFiscalCodeIsValid] = useState<boolean>(false);
	const [cart, setCart] = useState<Cart>();

	useEffect(() => {
		setCart({
			boltsGiven: item.boltsGiven,
			price: item.price,
			uid: item.uid,
			name: item.name,
			category: categories.GEMS_PACK,
			VAT: item.VAT,
		});
	}, [item]);

	useEffect(() => {
		setShowPaymentButtons(shouldShowPaymentButtons());
	}, [countrySelected, fiscalCodeIsValid]);

	const shouldShowPaymentButtons = () => {
		if (countrySelected.length === 0) return false;
		if (countrySelected === "Italy" && profile.fiscalCode != undefined) return true;
		return countrySelected === "Italy" ? fiscalCodeIsValid : true;
	};

	useEffect(() => {
		setFiscalCodeIsValid(FISCAL_CODE_REGEX.test(fiscalCode));
	}, [fiscalCode]);

	if (profile === null) return null;

	const closeDialog = () => {
		setCountrySelected("");
		setFiscalCode("");
		handleClose();
	};

	return (
		<>
			<Box
				component={Dialog}
				sx={{
					"& .MuiPaper-root": {
						minHeight: "400px",
					},
				}}
				open={open}
				onClose={closeDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title" textTransform="capitalize" fontSize={25} fontWeight="bold" sx={{ paddingTop: 0 }}>
					{t("purchasing")} {item.name}
				</DialogTitle>
				<DialogContent
					style={{
						flexGrow: 0,
					}}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="body1" sx={{ textAlign: "center", fontSize: "14px" }}>
								{t("with bolts you will be able to access eStorm services")} <br />
								{t("are you sure to proceed with the purchase")}
							</Typography>
						</Grid>
						<Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
							<span className="text-p">{t("new balance")} </span>
							<span className="text-p-bold" style={{ display: "flex", alignItems: "center", gap: 5 }}>
								{(profile.bolts + item.boltsGiven).toFixed(6)}{" "}
								<img src={`${myStorageUrl}images/bolt_sign.png`} className="bolt-sign" alt="bolt_sign" />
							</span>
						</Grid>
						<Grid item xs={12}>
							<Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
								<span className="text-p" style={{ float: "left" }}>
									{t("country.of.residence")}:
								</span>
								<CustomSelect
									onChange={(newValue) => {
										setCountrySelected(newValue);
									}}
									values={countryNames}
								/>
							</Box>
						</Grid>
						<Grid item xs={12}>
							{profile.fiscalCode !== undefined ||
								(countrySelected === "Italy" && !profile.fiscalCode && (
									<Box>
										<span className="text-p">{t("provide.fiscal.code.invoice")}:</span>
										<br />
										<Box paddingTop="15px" paddingBottom="15px">
											<Box textAlign="center">
												<InputText
													type="text"
													id="txtFC"
													idErr="errFC"
													placeholder="Fiscal code"
													fun={(e) => setFiscalCode(e.target.value.toUpperCase())}
												/>
											</Box>
											{!fiscalCodeIsValid && <label style={{ color: "red" }}>{"Codice fiscale incompleto o errato"}</label>}
										</Box>
									</Box>
								))}
						</Grid>
						<Grid item xs={12}>
							{showPaymentButtons && (
								<PaypalButton
									cart={cart}
									onSuccess={handleClose}
									boltsUsedForDiscount={0}
									fiscalCode={profile.fiscalCode == undefined ? fiscalCode : profile.fiscalCode}
								/>
							)}
						</Grid>
					</Grid>

					<DialogActions sx={{ justifyContent: "center" }}>
						<button
							onClick={handleClose}
							style={{ height: "45px", width: "30%", border: "1px solid #000", marginLeft: 0, textDecoration: "underline" }}
							type="button"
							className={`btn-outline text-p`}>
							{t("cancel")}
						</button>
					</DialogActions>
				</DialogContent>
			</Box>
		</>
	);
};
