import React, { useContext } from "react";
import { NetworkContext } from "../../Providers/NetworkProvider";
import "../../../styles/paypal.scss";
import toast from "react-hot-toast";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { AuthContext } from "../../Providers/AuthProvider";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import { Profile } from "../../../types/User";

export type PayPalCart = {
	boltsGiven: number;
	uid: number;
	name: string;
	category: string;
};

export type PayPalButtonProps = {
	cart: PayPalCart;
	onSuccess: () => void;
	fiscalCode?: string;
	boltsUsedForDiscount: number;
};

type InnerPaypalButtonProps = PayPalButtonProps & {
	makePost: <T = any>(url: string, body: any) => Promise<AxiosResponse<T, any>>;
	profile: Profile;
	t: any;
};

const style = { layout: "vertical" };

/**
 * !! IMPORTANT !!
 *
 * DO NOT USE FUNCTIONAL COMPONENT HERE
 *
 * The PayPalButtons component from the PayPal SDK does not work with functional components.
 *
 * Because when `this.props.boltsUsedForDiscount` changes, the component does not re-render.
 * So, the `this.createOrder` function is not updated with the new value of `this.props.boltsUsedForDiscount`.
 * With a class component, we are binding the function to the class instance, so it will always have the latest value of `this.props.boltsUsedForDiscount`.
 *
 */
class InnerPaypalButton extends React.Component<InnerPaypalButtonProps> {
	constructor(props: InnerPaypalButtonProps) {
		super(props);

		this.paypalToken = this.paypalToken.bind(this);
		this.onApprove = this.onApprove.bind(this);
		this.createOrder = this.createOrder.bind(this);
	}

	paypalToken() {
		if (process.env.REACT_APP_ENV.includes("DEV")) {
			return "Aal1HQpa_exGgVU6xSxvr2nz-2q85_woxpG9T_bvsXSmMJLBGjcGatpL-_tUvO-6dQupdFaYby4jJ8EW";
		} else {
			return "AUIAY6huM5LwDvL6XktYq00crpH4YmT715E75c3zmtZyBtymnAegzcwnSSiPJKMGpt_J4w10wJU86ewK";
		}
	}

	async createOrder() {
		try {
			const body = {
				_token: `Bearer ${this.props.profile.idToken}`,
				cart: {
					uid: this.props.cart.uid,
					name: this.props.cart.name,
					boltsGiven: this.props.cart.boltsGiven,
					category: this.props.cart.category,
					boltsUsedForDiscount: this.props.boltsUsedForDiscount,
				},
				fiscalCode: this.props.fiscalCode,
			}
			const order = await this.props.makePost(`/orders`, body);
			if (order == null) {
				throw { message: "PayPal error" };
			}
			if (order.status != 201) {
				throw { ...order.data, status: order.status };
			}
			return order.data.id;
		} catch (err) {
			toast.error(err.message);
			console.log(err);
			return "false";
		}
	}

	async onApprove(data: any) {
		return this.props
			.makePost(`/orders/${data.orderID}/capture`, {
				orderId: data.orderID,
			})
			.then((order) => {
				if (order.status != 201) {
					throw { ...order.data, status: order.status };
				}
				console.log(order);
				toast.success(this.props.t("successful purchase"));
				this.props.onSuccess();
			})
			.catch((err) => {
				console.log(err);
				toast.error(err.message);
			});
	}

	render() {
		return (
			<PayPalScriptProvider
				options={{
					clientId: this.paypalToken(),
					components: "buttons",
					currency: "EUR",
				}}>
				<div style={{ width: "100%" }}>
					<PayPalButtons
						style={{ disableMaxWidth: true, shape: "pill" }}
						disabled={false}
						forceReRender={[style]}
						fundingSource={undefined}
						createOrder={this.createOrder}
						onApprove={this.onApprove}
						onCancel={() => {
							toast.error(this.props.t("purchase cancelled"));
						}}
						onError={(err) => {
							console.error(err);
						}}
					/>
				</div>
			</PayPalScriptProvider>
		);
	}
}

/**
 * Wrapper for the InnerPaypalButton class component
 */
export const PaypalButton = (props: PayPalButtonProps) => {
	const { makePost } = useContext(NetworkContext);
	const { profile } = useContext(AuthContext);
	const { t } = useTranslation("dashboard");

	return <InnerPaypalButton {...props} makePost={makePost} profile={profile} t={t} />;
};
