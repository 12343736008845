export const isEmail = (email: string): boolean => {
	const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (email.match(regEx)) return true;
	else return false;
};

export const isPassword = (password: string): boolean => {
	const regEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*#!@$%^&(){}[\]:;<>,.°\/§?'~_+=|-]).{8,}$/;
	return password.match(regEx) ? true : false;
};

export const isEmpty = (string: string): boolean => {
	if (string.trim() === "") return true;
	else return false;
};
