import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CookiesTable } from "./CookiesTable";

export const ListOfCookiesInfo = () => {
    const { t } = useTranslation(['cookies']);
    return (<>
        <ol>
            <li>{t('list.one')}</li>
            <ol>
                <li>{t('list.one.one')}</li>
            </ol>
            <li>{t('list.two')}</li>
            <ol>
                <li>{t('list.two.one')}</li>
                <ol type={'a'}>
                    <li>{t('list.two.one.a')}</li>
                    <li>{t('list.two.one.b')}</li>
                    <li>{t('list.two.one.c')}</li>
                    <li>{t('list.two.one.d')}</li>
                </ol>
                <li>{t('list.two.two')}</li>
            </ol>
            <li>
                {t('list.three')}
                <ol>
                    <li>{t('list.three.one')}</li>
                    <CookiesTable />
                </ol>
            </li>
            <Typography variant="body1" paddingTop={'2em'} paddingBottom={'2em'}><b>{t('list.attention.message')}</b></Typography>
            <li>
                <b>{t('list.four')}</b>
                <ol>
                    <li>
                        {t('list.four.one')}
                        <ol type={'a'}>
                            <li>{t('list.four.one.a')}</li>
                            <li>{t('list.four.one.b')}</li>
                            <li>{t('list.four.one.c')}</li>
                        </ol>
                    </li>
                    <li>
                        {t('list.four.two')}
                        <ol type='a'>
                            <li>{t('list.four.two.a')} <a href='https://support.mozilla.org/it/kb/Gestione%20dei%20cookie'> Cookie Management | Firefox Support</a></li>
                            <li>{t('list.four.two.b')} <a href='https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies'> Deleting and Managing Cookies in Internet Explorer</a> </li>
                            <li>{t('list.four.two.c')} <a href='https://support.google.com/chrome/answer/95647?hl=it'> Deleting, enabling and managing cookies in Chrome - Android - Google Chrome Help</a></li>
                            <li>{t('list.four.two.d')} <a href='http://help.opera.com/Windows/10.00/it/cookies.html'> Web preferences - Opera Help</a></li>
                            <li>{t('list.four.two.e')}<a href='https://support.apple.com/it-it/guide/safari/sfri11471/mac'> https://support.apple.com/it-it/guide/safari/sfri11471/mac</a></li>
                            <li>{t('list.four.two.f')} <a href='http://support.apple.com/kb/HT1677?viewlocale=it_IT'> How to delete history and cookies from Safari on iPhone, iPad or iPod touch</a></li>
                        </ol>
                    </li>
                    <li>{t('list.four.three')}</li>
                    <li>{t('list.four.four')}</li>
                    <li>{t('list.four.five')}</li>
                </ol>
                <li>
                    <b>{t('list.five')}</b>
                    <ol>
                        <li>
                            {t('list.five.one')}
                        </li>
                    </ol>
                </li>
            </li>
        </ol>
    </>);
}