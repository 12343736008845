import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import "../../../styles/home/aboutUs/HowWeStarted.scss";

import "../../../styles/home/aboutUs/TeamPopup.scss";
import { TeamMemberType } from "../../../types/Home/AboutUs/TeamMemberPopup";
import { TeamPopup } from "./Team/TeamPopup";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "../../Providers/FirebaseProvider";
import { GetTeamInformation } from "../../../Team/teamInformation";

export const HowWeStarted = () => {
	const [highlightedSection, setHighlightedSection] = useState<number | null>(null);
	const { t } = useTranslation(["landing"]);
	useEffect(() => {
		const handleScroll = () => {
			const sections = document.querySelectorAll(".text-section");

			for (let i = 0; i < sections.length; i++) {
				const section = sections[i] as HTMLElement;
				const rect = section.getBoundingClientRect();
				const windowHeight = window.innerHeight || document.documentElement.clientHeight;
				const distanceFromBottom = windowHeight - rect.bottom;

				if (distanceFromBottom > 0 && distanceFromBottom >= windowHeight * 0.2) {
					setHighlightedSection(i);
				}
			}
		};

		document.body.addEventListener("scroll", handleScroll);

		return () => {
			document.body.removeEventListener("scroll", handleScroll);
		};
	}, []);
	return (
		<>
			<Grid container justifyContent="center">
				<Grid item xs={11} xl={8} display="flex" flexDirection="column" justifyContent="center" alignItems="center" rowGap={5}>
					<Grid container className="founders-container" justifyContent="center" alignItems="center" rowGap={1}>
						<Grid item xs={12} sm={6} className="founder-images">
							<Founders />
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography
								variant="h4"
								color="white"
								className={`text-section ${highlightedSection >= 0 ? "highlighted" : ""} gradient-text-conatainer story-telling-text`}
								textAlign="left">
								{t("aboutus.how.we.started.title#1")}
								<span className="gradient-text"> {t("aboutus.how.we.started.title#2")} </span>
								{t("aboutus.how.we.started.title#3")}
								<span className="gradient-text"> {t("aboutus.how.we.started.title#4")}</span>
							</Typography>
						</Grid>
						<Grid item xs={12} sm={11} mt={30}>
							<Typography
								variant="h4"
								color="white"
								className={`text-section ${highlightedSection >= 1 ? "highlighted" : ""} gradient-text-conatainer story-telling-text`}>
								<span className="gradient-text">{t("aboutus.how.we.started.origin#1")}</span> {t("aboutus.how.we.started.origin#2")}
								<span className="gradient-text"> {t("aboutus.how.we.started.origin#3")}</span> {t("aboutus.how.we.started.origin#4")}
								<span className="gradient-text">{t("aboutus.politecnico")}</span> {t("aboutus.and.by")}
								<span className="gradient-text"> {t("aboutus.bocconi")}</span>
								{t("aboutus.introduction")}
							</Typography>
						</Grid>

						<Grid item xs={12} sm={11}>
							<Typography
								variant="h4"
								color="white"
								className={`text-section ${highlightedSection >= 2 ? "highlighted" : ""} gradient-text-conatainer story-telling-text`}></Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

const Founders = () => {
	const [isPopupOpen, setisPopupOpen] = useState<boolean>(false);
	const [teamMember, setTeamMember] = useState<TeamMemberType | null>(null);
	const { myStorageUrl } = useContext(FirebaseContext);

	const handlePopupOpen = (member: TeamMemberType) => {
		setTeamMember(member);
		setisPopupOpen(true);
	};
	const handleClosePopup = () => {
		setisPopupOpen(false);
	};
	const teamInformation = GetTeamInformation();

	const rubenInformation: TeamMemberType = teamInformation.find((member) => member.name === "Ruben Soro");
	const alessioInformation: TeamMemberType = teamInformation.find((member) => member.name === "Alessio di Dio");
	return (
		<>
			{teamMember !== null && <TeamPopup teamInfo={teamMember} isPopupOpen={isPopupOpen} handleClosePopup={handleClosePopup}></TeamPopup>}
			<Grid className="founder-images" container justifyContent="center" alignItems="center">
				<Grid className="founder-images" item xs={12} sx={{ position: "relative" }}>
					<div id="first-bubble-founders-background"></div>
					<div id="second-bubble-founders-background"></div>
					<div id="third-bubble-founders-background"></div>
					<div id="fourth-bubble-founders-background"></div>
					<div className="team-member-image" id="ruben-soro-founder" onClick={() => handlePopupOpen(rubenInformation)}>
						<img src={`${myStorageUrl}images/team_pictures/new_landing_ruben_soro.png`} alt="Ruben Soro Founder"></img>
					</div>

					<div className="team-member-image" id="alessio-di-dio-founder" onClick={() => handlePopupOpen(alessioInformation)}>
						<img src={`${myStorageUrl}images/team_pictures/new_landing_alessio_di_dio.png`} alt="Alessio Di Dio Founder"></img>
					</div>
				</Grid>
			</Grid>
		</>
	);
};
