import React, { useContext, useEffect, useState } from "react";
import { StatsContext } from "../../Providers/StatsProvider";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { AuthContext } from "../../Providers/AuthProvider";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Label, Dot } from "recharts";
import { DisclamerHover } from "../DisclamerHover";
import DropdownList from "react-widgets/DropdownList";
import "react-widgets/styles.css";

import "../../../styles/DropDown.scss";
import { CustomTooltipProps, LegendType, PercentileGraph } from "../../../types/Histories";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import { HistoryPlacementsProps } from "../../../types/Props";
import $ from "jquery";
import { SettingsContext } from "../../Providers/SettingsProvider";

export const HistoryPlacements = (props: HistoryPlacementsProps) => {
	const { t } = useTranslation("dashboard");

	const { historyPlacements, mapPlacements, maxDailyPointsValue, historyPlacementsGraphData } = useContext(StatsContext);
	const { changeActiveDashboard } = useContext(SettingsContext);
	
	const [percentileToShowOnGraph, setPercentileToShowOnGraph] = useState({ id: "none", name: "None" });

	const [legendData, setLegendData] = useState([
		{ value: t("daily points"), type: "line", id: "ID01", index: 0, color: "#8884d8", dataKey: "dailyPoints", inactive: false },
	] as LegendType[]);
	const { profile } = useContext(AuthContext);

	const percentileAvailable: PercentileGraph[] = [
		{ id: "none", name: "None" },
		{ id: "firstPercentile", name: "Top 1%" },
		{ id: "secondPercentile", name: "Top 10%" },
		{ id: "thirdPercentile", name: "Top 25%" },
	];

	const changePercentileGraph = (newPercentile): void => {
		setPercentileToShowOnGraph(newPercentile);
		let legend = [...legendData];

		if (newPercentile.id == "none") {
			legend.pop();
			setLegendData(legend);
		} else {
			if (legend.length > 1) {
				legend.pop();
			}
			legend.push({
				value: t("daily points of tier") + " " + newPercentile.name,
				type: "line",
				color: "#eb4689",
				dataKey: newPercentile.id,
				inactive: false,
				id: "ID02",
				index: 1,
			} as LegendType);
			setLegendData(legend);
		}
	};

	useEffect(() => {
		
	}, [historyPlacements]);

	useEffect(() => {
		if (profile != null && !profile.isPremium) {
			$(".history-placements-graph").css("filter", "blur(3px)");
		}
	}, [profile]);

	const CustomTooltip = ({ active, payload, label }: CustomTooltipProps): JSX.Element => {
		if (active && payload && payload.length) {
			return (
				<>
					{profile != null && profile.isPremium && (
						<div className="hover-information-chart-container">
							<span className="text-label leaderboard-date">{`${label}`}</span>

							<span className="text-label">
								{t("number of player")}: {mapPlacements.get(label).playersInLeaderboard}
							</span>
							<span className="text-label">
								{t("daily points")}: {payload[0].value}
							</span>
							{percentileToShowOnGraph.id !== "none" ? (
								<>
									<span className="text-label">
										{percentileToShowOnGraph.name}: {payload[1].value} {t("daily points")}
									</span>
								</>
							) : (
								<>
									<span className="text-label">Tier: {mapPlacements.get(label).dailyPercentile}</span>
								</>
							)}
						</div>
					)}
				</>
			);
		}

		return null;
	};

	const htmlHover = (
		<>
			<span className="text-label">{t("this chart will allow you to analyse your stats and completed goals")}</span>
			<br />
			<span className="text-label">
				{t("you will be able to compare your data with players of higher tier than yours to find out how to level up and earn more Bolts")}
			</span>
		</>
	);
	const hoverStyle = {
		background: "linear-gradient(90deg, #AB00C7 0.58%, #0075FF 97.84%)",
		color: "rgba(255, 255, 255, 0.87)",
		minWidth: 300,
	};

	return (
		<>
			<Grid container sx={{ height: "100%" }}>
				<Grid item xs={12} className="history-placements-container">
					{historyPlacements && (
						<>
							<div className="history-placements-title">
								<div>
									<span className="text-h3">{t("daily placements graph")}</span>
								</div>
								{profile != null && profile.isPremium && (
									<div className="graphs-hover-and-dropdown">
										<DisclamerHover
											placement={"bottom-start"}
											htmlHover={htmlHover}
											hoverStyle={hoverStyle}
											disclamerOpen={props.disclamerGraphHoverOpen}
											setDisclamerOpen={props.setDisclamerGraphHoverOpen}
										/>
										<div className="dropdown-percentiles">
											<DropdownList
												filter={false}
												value={percentileToShowOnGraph}
												dataKey="id"
												textField="name"
												onChange={(nextValue) => changePercentileGraph(nextValue)}
												data={percentileAvailable}
											/>
										</div>
									</div>
								)}
							</div>

							<div className="history-placements-graph">
								{profile.favouriteGame != null ? (
									<>
										<ResponsiveContainer height="100%">
											<LineChart width={400} height={300} data={historyPlacementsGraphData}>
												<CartesianGrid strokeDasharray="3 3" />
												<XAxis dataKey="name" />
												<YAxis domain={[0, maxDailyPointsValue]}>
													<Label
														style={{
															textAnchor: "middle",
															fontSize: "130%",
															fill: "white",
														}}
														angle={270}
														value={t("daily points")}
													/>
												</YAxis>
												<Tooltip content={<CustomTooltip />} />
												<Legend payload={legendData as Payload[]} />
												<Line
													type="monotone"
													dataKey="dailyPoints"
													name={t("daily points")}
													stroke="#8884d8"
													strokeWidth={3}
													activeDot={{ r: 8 }}
													dot={{ strokeWidth: 3, strokeOpacity: 0.3, r: 5 }}></Line>

												{percentileToShowOnGraph?.id !== "none" && (
													<>
														<Line
															type="monotone"
															dataKey={percentileToShowOnGraph.id}
															name={t("daily points of tier") + " " + percentileToShowOnGraph.name}
															stroke="#eb4689"
															strokeWidth={3}
															activeDot={{ r: 8 }}
															dot={{ strokeWidth: 3, strokeOpacity: 0.3, r: 5 }}
														/>
													</>
												)}
											</LineChart>
										</ResponsiveContainer>
									</>
								) : (
									<Grid container columns={16} justifyContent={"center"} alignItems={"center"} sx={{ height: "100%" }}>
										<Grid
											item
											xs={5}
											sx={{
												height: "fit-content",
												textAlign: "center",
												padding: "16px",
												borderRadius: "16px",
												borderColor: "white",
												border: "1px solid",
											}}>
											{profile.favouriteGame == null ? (
												<p className="text-p no-game-connected-text">{t("connect and choose your favourite game to access quests")}</p>
											) : (
												<p className="text-p no-game-connected-text">Unlock premium features with E-Storm+</p>
											)}
										</Grid>
									</Grid>
								)}
							</div>
							{profile != null && !profile.isPremium && (
								<Grid
									item
									xs={8}
									sx={{ padding: "1em", borderRadius: "16px", borderColor: "white", border: "1px solid", fontSize: "14px" }}
									className="unlock-premium-features">
									<a
										className="text-p no-game-connected-text"
										onClick={() => {
											changeActiveDashboard("market");
										}}>
										{t("upgrade to premium")}
									</a>
								</Grid>
							)}
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
};
