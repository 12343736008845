import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper";
import { Inventory } from "../../../../types/Payments";
import { ReactComponent as SilverBox } from "../../../../assets/images/silver_box.svg";
import { ReactComponent as VoidBox } from "../../../../assets/images/void_box.svg";
import { ReactComponent as GoldBox } from "../../../../assets/images/gold_box.svg";
import { ReactComponent as GalaxyBox } from "../../../../assets/images/galaxy_box.svg";

const BOXES = { silverBox: SilverBox, voidBox: VoidBox, goldBox: GoldBox, galaxyBox: GalaxyBox } as const;

type SwiperBoxProps = {
	setSelected: React.Dispatch<React.SetStateAction<Inventory.ItemsName>>;
	swiper: SwiperType;
	setSwiper: React.Dispatch<React.SetStateAction<SwiperType>>;
};

export const SwiperBox = ({ setSelected, swiper, setSwiper }: SwiperBoxProps) => {
	return (
		<Swiper
			key={`swiper-gems-pack`}
			spaceBetween={30}
			id={`swiper-gems-pack`}
			slidesPerView={1}
			onInit={setSwiper}
			initialSlide={0}
			onSlideChange={() => {
				setSelected(Object.keys(BOXES)[swiper.activeIndex] as Inventory.ItemsName);
			}}>
			{Object.entries(BOXES).map(([key, Box]) => (
				<SwiperSlide
					key={"slide-" + key}
					className="next "
					style={{
						display: "flex",
						justifyContent: "center",
					}}>
					<Box title={key} width="250px" height="250px" />
				</SwiperSlide>
			))}
		</Swiper>
	);
};
