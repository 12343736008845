import { createContext } from "react";
import { GamesProvider } from "../../types/Providers";
import pathsInBucket from "../../utilities/costants/links";
import { gamesInStorm } from "../../utilities/costants/genericCostants";

export const GamesContext = createContext<GamesProvider | null>(null);
export const GamesProviderImpl = (props): JSX.Element => {
	const children = props.children;

	const getGameSrcByName = (name: string): string => {
		let src = '';
		switch (name) {
			case gamesInStorm.fortnite:
				src = pathsInBucket.images.games.fortniteLogo;
				break;
			case gamesInStorm.dota:
				src = pathsInBucket.images.games.dotaLogo;
				break;
			default:
				console.error('Game not found');
		}
		return src;
	}

	const gamesProviderData: GamesProvider = {
		getGameSrcByName
	};
	
	return <GamesContext.Provider value={gamesProviderData}>{children}</GamesContext.Provider>;
};
